import React from "react";
import { Modal } from "antd";
import "./userInfoCard.css";
import MapComponent from "../OrderMailScreen/MapComponent";
import { isDriverUser } from "../../utils/helper";

const AddressModal = ({ addressModal, setAddressModal, address }) => {
  const { tag, houseNumber, area, landMark, location } = address || {};
  
  return (
    <Modal
      title={`Ramesh's Address`}
      centered
      cancelText="Close"
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { className: "antdCancelButton" } }}
      open={addressModal}
      onOk={() => setAddressModal(false)}
      onCancel={() => setAddressModal(false)}
    >
      <div>
        <div
          style={{ width: "fit-content", marginLeft: 0, fontWeight: 600 }}
          className="orderBillStatusLabel"
        >
          {tag?.toUpperCase()}
        </div>
        <p className="addressParagraph">
          {houseNumber}
          {area && `, ${area}`}
          {landMark && `, ${landMark}`}
          {location?.locationName && `, ${location?.locationName}`}
        </p>
        <MapComponent {...location} />
        
      </div>
    </Modal>
  );
};

export default AddressModal;
