
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import "./chefDishesDrawer.css";
import EditDishModal from './EditDishModal';
import ChefDishCardComponent from './ChefDishCardComponent';
import { Spin } from 'antd';
import AddCategoryModal from '../AddCategoryModal/Index';

const ChefDishesComponent = ({ chefDetails }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDishClicked, setNewDishClicked] = useState(false);
    const [modalDishInfo, setModalDishInfo] = useState({});
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const chefDishesList = useSelector(
        (state) => state.orders.chefDishes.data
    );
    const chefCategoriesList = useSelector(
        (state) => state.orders.chefCategoriesList.data
    );
    const chefDishesListLoading = useSelector(
        (state) => state.orders.chefDishes.loading
    );
    const chefId = chefDetails._id;
    const chefDishes = chefDishesList[chefId] || [];
    const allchefCategoriesList = chefCategoriesList[chefId] || [];

    const chefCategories = chefDishes.map((c) => ({ value: c._id, label: c.name }))
    const allchefCategories = allchefCategoriesList.map((c) => ({ value: c._id, label: c.name }))

    const showModal = (dishInfo) => {
        setModalDishInfo(dishInfo)
        setIsModalOpen(true);
        setNewDishClicked(false)
    };


    const handleCancel = () => {
        setIsModalOpen(false);
        setModalDishInfo({})
        setNewDishClicked(false)
    };

    const combinedDishesArray = [].concat(...chefDishes.map(obj => {
        return obj.dishes.map((a) => ({ ...a, categoryId: obj._id }))
    }));


    const handleOpenAddDish = () => {
        setNewDishClicked(true)
        setModalDishInfo({ type: "veg" })
        setIsModalOpen(true);
    }

    return (
        <div className='ChefDishesComponentWrapper'>
            <div className='addDishBtnWrapper'>
                <button onClick={() => setIsCategoryModalOpen(true)} style={{ border: 0, marginRight: 10, background: "#1677ff", color: "#fff" }} className='editDishBtn' >
                    Add category
                </button>
                <button onClick={handleOpenAddDish} style={{ border: 0, background: "#1677ff", color: "#fff" }} className='editDishBtn' >
                    Add dish
                </button>
            </div>
            {(!chefDishes?.length && chefDishesListLoading) && <Spin />}
            {allchefCategoriesList.map((category) => {
                const categoryDishes = category.dishes.map((a) => ({ ...a, categoryId: category._id }));
                return <div>
                    <h5 className='categoryHeaderLabel' style={{marginBottom: 10}} >Category: {category.name}</h5>
                    {categoryDishes.map((dish, index) => <div key={index}><ChefDishCardComponent dish={dish} showModal={showModal} /></div>)}
                    {!categoryDishes.length && <div style={{marginLeft: 20}} >No dishes available</div> }
                </div>
            })}
            {/* {combinedDishesArray.map((dish, index) => <div key={index}><ChefDishCardComponent dish={dish} showModal={showModal} /></div>)} */}
            <EditDishModal handleCancel={handleCancel} chefCategories={allchefCategories} newDishClicked={newDishClicked} isModalOpen={isModalOpen} chefDetails={chefDetails} modalDishInfo={modalDishInfo} />
            <AddCategoryModal isCategoryModalOpen={isCategoryModalOpen} setIsCategoryModalOpen={setIsCategoryModalOpen} combinedDishesArray={combinedDishesArray} allchefCategoriesList={allchefCategoriesList} chefDetails={chefDetails} />
        </div>
    )
}

export default ChefDishesComponent