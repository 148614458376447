import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { postLiveLocation } from "../redux/actions/OrderAction";
import { useDispatch, useSelector } from "react-redux";

const LiveLocationTracker = ({ currentOrderId, driverId }) => {
  const dispatch = useDispatch();
  const liveLocationReducer = useSelector(
    (state) => state.orders.liveLocation
  );
  const [liveLocation, setLiveLocation] = useState(null);

  useEffect(() => {
    // Get the user's current location using the Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLiveLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting live location:", error);
        }
      );
    }

    // Send the initial live location to the server
    sendLiveLocationToServer(liveLocation);

    // Start interval to call sendLiveLocationToServer every 1 minute
    const intervalId = setInterval(() => {
      sendLiveLocationToServer(liveLocation);
    }, 10000); // 1 minute in milliseconds

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener and clear the interval when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearInterval(intervalId);
    };
  }, [liveLocation]);

  const handleBeforeUnload = (event) => {
    // This function will be called when the user closes the tab or navigates away from the page.
    // Send the last known live location to the server here
    if (liveLocation) {
      sendLiveLocationToServer(liveLocation);
    }

    // You can show a confirmation message to the user if you want by setting the event.returnValue property
    // event.returnValue = 'Are you sure you want to leave?';
  };

  const sendLiveLocationToServer = (location) => {
    // Implement your API call here to send the location data to the server
    // For example, you can use fetch or axios to make a POST request to your server endpoint
    if(!driverId || !currentOrderId || !location?.lat || !location?.lng) return;
    const locationAlreadyShared = liveLocationReducer?.orderId === currentOrderId;

    const payload = {
      driverId: driverId,
      orderId: currentOrderId,
      lat: location ? location.lat : null,
      lng: location ? location.lng : null,
    }
    if(locationAlreadyShared){
      delete payload.driverId
    }
    dispatch(
      postLiveLocation(payload)
    );
  };

  return <div></div>;
};

export default LiveLocationTracker;