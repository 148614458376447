import React from "react";

const SwipeUpArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      version="1"
      fill="#fff"
      viewBox="0 0 512 512"
    >
      <path
        d="M2459 5104c-26-8-68-29-93-46-52-37-2086-2183-2121-2239-91-142-64-327 65-443 112-101 288-114 415-31 22 15 443 453 935 975s897 948 900 948 381-398 840-884 868-918 909-959c104-104 158-130 276-130 104 0 156 19 225 81 75 68 120 166 120 259 0 53-25 138-55 184-35 56-2069 2202-2121 2238-84 60-197 78-295 47z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M2459 2815c-26-8-68-30-94-47-26-18-512-525-1081-1127C165 457 205 504 193 377 165 77 530-115 755 81c21 19 435 453 919 964 484 512 883 930 886 930s370-385 814-855c445-470 854-901 910-958C4414 30 4460 5 4580 5c76 0 92 4 152 33 77 38 130 92 167 171 22 47 26 69 26 141-1 71-5 94-28 141-22 46-221 261-1074 1163-976 1032-1052 1109-1110 1138-77 37-180 47-254 23z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
};

export default SwipeUpArrow;
