import React, { useState } from "react";
import { Avatar } from "antd";
import CallIcon from "../../assets/ReactIcon/CallIcon";
import LocationIcon from "../../assets/ReactIcon/LocationIcon";
import AddressModal from "./AddressModal";
import "./userInfoCard.css";
import moment from "moment";
import { isDriverUser } from "../../utils/helper";

const UserInfoCard = (props) => {
  const {
    status,
    showOnlyLocationButton,
    assignedDeliveryId,
    deliveryAgentName,
    createdAt,
    order,
    showPrintKot = false
  } = props;
  const {
    _id,
    bookedDishes,
    instructionText,
    orderRef,
  } = order || {};
  const [addressModal, setAddressModal] = useState(false);

  const handlePrint = () => {
    const printWindow = window.open(" ", " ", "width=1200,height=1200");
    printWindow.document.open();
    printWindow.document.write(`
    <div className="order-receipt" style="padding: 5px; margin-left: -8px; height: max-content; width: 260px; border: 1px solid #ccc; font-family: Arial, sans-serif;">
    <div className="header" style="text-align: center; font-size: 18px; font-weight: bold; margin-bottom: 10px;">Cuirato Order Receipt</div>
    <div className="order-item" style="display: flex; justify-content: space-between; margin-bottom: 5px;">
      <div className="item-name" style="flex-grow: 1;">Bill No: ${orderRef || "N/A"
      }</div>
    </div>
    <div>------------------------------------------------</div>
    ${bookedDishes
        .map(
          (item, index) => `
      <div className="order-item" style="display: flex; justify-content: space-between; margin-bottom: 5px;" key="${index}">
        <div className="item-name" style="flex-grow: 1; font-size: 18px; font-weight: bold;">${item?.dish?.name}${item?.quantity && `(${item?.quantity})`}</div>
        <div className="item-quantity" style="flex-shrink: 0; font-size: 18px; font-weight: bold;">
          ${item?.portion}
        </div>
      </div>
    `
        )
        .join("")}
      <div>------------------------------------------------</div>
     ${instructionText && `<div className="item-name" style="flex-grow: 1;">${instructionText}</div>`
      }
  </div>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  if (showOnlyLocationButton && !assignedDeliveryId) {
    return (
      <>
        <div className="trackLocationBtnWrapper">
          <button
            onClick={() => setAddressModal(true)}
            className="transparentLocationButton"
          >
            <LocationIcon /> Show location
          </button>
          {showPrintKot && <button
            onClick={() => handlePrint()}
            className="transparentLocationButton"
          >
            Print KOT
          </button>}
        </div>
        <AddressModal
          addressModal={addressModal}
          {...props}
          setAddressModal={setAddressModal}
        />
      </>
    );
  }

  const getDriverStatusDetails = () => {
    switch (status) {
      case "DELIVERED":
        return `${deliveryAgentName} Delivered Food`
      case "PICKED_UP":
        return `${deliveryAgentName} is on the way`

      default:
        return `${deliveryAgentName} is waiting for ${moment(createdAt).toNow(true)}`
    }
  }

  return (
    <div className="userInfoCardBoxWrapper">
      <div className="userInfoCardBoxHeader">
        <div className="userInfoNameCardWrapper">
          <Avatar
            size={34}
            src={"https://cdn-icons-png.flaticon.com/512/4140/4140077.png"}
          />
          <div className="userNameInfoDetails">
            <span className="userNameInfoDetailsText">
              {getDriverStatusDetails()}
            </span>
            {/* <span className="userNameInfoDetailsOtp">OTP: 4528</span> */}
          </div>
        </div>
        {!isDriverUser() && <CallIcon />}

      </div>
      <div className="trackLocationBtnWrapper">
        <button
          onClick={() => setAddressModal(true)}
          className="transparentLocationButton"
        >
          <LocationIcon /> Track location
        </button>
        {showPrintKot && <button
          onClick={() => handlePrint()}
          className="transparentLocationButton"
        >
          Print KOT
        </button>}
      </div>
      <AddressModal
        addressModal={addressModal}
        {...props}
        setAddressModal={setAddressModal}
      />
    </div>
  );
};

export default UserInfoCard;
