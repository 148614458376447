import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KitchenTopBar from "./KitchenTopBar";
import OrderViewCard from "../OrderViewCard/Index";
import NewOrderBottomBar from "../NewOrderBottomBar/Index";
import { updateOrders } from "../../redux/actions/OrderAction";
import { isDriverUser } from "../../utils/helper";
import Loader from "../Loader/Index";
import "./orderScreen.css";
import moment from "moment";
import LiveLocationTracker from "../../LiveLocationTracker/Index";

const OrderScreen = ({ isDesktopView, getOrdersAction }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(isDriverUser() ? "READY" : "PREPARING");
  const orderReducer = useSelector((state) => state.orders.orders);

  const {
    data: orderDataList = [],
    confirmedOrders = [],
    readyOrders = [],
    pickedUpOrders = [],
    loading,
  } = orderReducer;

  const pickedUpOrderDataList = orderDataList.filter(
    (a) => a.status === "PICKED_UP"
  );
  const deliveredOrderDataList = orderDataList.filter(
    (a) => a.status === "DELIVERED"
  );

  const getFilteredOrderDataList = () => {
    switch (status) {
      case "PREPARING":
        return confirmedOrders;
      case "READY":
        return readyOrders;
      case "PICKED_UP":
        if (isDriverUser()) {
          return pickedUpOrderDataList;
        } else {
          return pickedUpOrders;
        }
      case "DELIVERED":
        return deliveredOrderDataList;
    }
    return orderDataList;
  };

  const onButtonClick = (id) => {
    const statusText = isDriverUser() ? (status === "PICKED_UP" ? "DELIVERED" : "PICKED_UP") : (status === "READY" ? "PICKED_UP" : "READY");
    const orderObj = {
      id: id,
      status: statusText,
    }
    if (statusText === "DELIVERED") {
      orderObj.deliveredDate = moment().format()
    }
    dispatch(
      updateOrders(orderObj)
    );
  };

  const getAcceptButtonText = () => {
    switch (status) {
      case "PREPARING":
        return "Order ready";
      case "READY":
        return "Picked Up";
      case "PICKED_UP":
        if (isDriverUser()) {
          return "Delivered";
        }
      default:
        return;
    }
  };

  const fetchItems = async () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 200 >=
      document.documentElement.offsetHeight
    ) {
      fetchItems();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleChangeStatus = (status) => {
    setPage(1)
    setStatus(status)
  }

  const getFilteredOrderDataListArray = getFilteredOrderDataList()?.slice(0, page * 10);

  const driverId = localStorage.getItem("userId");
  const [driverFirstOrder] = pickedUpOrderDataList || []
  const driverFirstOrderId = driverFirstOrder?._id
  if (loading && !getFilteredOrderDataListArray) {
    return <Loader />;
  }

  return (
    <div className="kitchenWrapper">
      <KitchenTopBar
        status={status}
        confirmedOrdersCount={confirmedOrders?.length}
        readyOrdersCount={readyOrders?.length}
        pickedUpOrdersCount={pickedUpOrders?.length}
        pickedUpOrderDataListCount={pickedUpOrderDataList?.length}
        deliveredOrderDataListCount={deliveredOrderDataList?.length}
        setStatus={handleChangeStatus}
        showStatusBar={true}
        isHomePage={true}
      />
      <div className="orderViewCardWrapper">

        {getFilteredOrderDataListArray.map((order) => (
          <OrderViewCard
            key={order._id}
            acceptButtonText={getAcceptButtonText()}
            productNameUnderline
            showOnlyLocationButton
            order={order}
            isDesktopView={isDesktopView}
            onButtonClick={onButtonClick}
          />
        ))}
        {!getFilteredOrderDataListArray.length && (
          <h5 className="noOrderHeader">No Orders</h5>
        )}
      </div>
      <NewOrderBottomBar isDesktopView={isDesktopView} getOrdersAction={getOrdersAction} />
      {(isDriverUser() && driverFirstOrderId) &&
        <>{pickedUpOrderDataList.map((chef) => (
          <LiveLocationTracker
            currentOrderId={chef?._id}
            driverId={driverId}
          />
        ))}
        </>}
    </div>
  );
};

export default OrderScreen;
