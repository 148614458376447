import { Button, Checkbox, Input, InputNumber, Switch } from 'antd'
import React from 'react'

const CustomizeComponent = ({ customizeValue, onCustomizeObjValueChange, updateOptionValueById, onNewCustomizationOptionClick }) => {

  const onChangeOptional = (e) => {
    onCustomizeObjValueChange(customizeValue.id, "isOptional", e.target.checked)
  }

  const onLabelChange = (e) => {
    onCustomizeObjValueChange(customizeValue.id, "label", e.target.value)
  }

  const customizeOptions = customizeValue.options || [];

  return (
    <div className='CustomizeComponentBox'>
      <div className='customizeLabelWrapper'>
        <span style={{ fontSize: 13 }} ><Input className='editDishInfoModal' onChange={onLabelChange} value={customizeValue.label} /></span>
        <span style={{ fontSize: 13 }} ><Checkbox className='chefDishAvailabilityCheckbox' defaultChecked={customizeValue?.isOptional} onChange={onChangeOptional} >Optional</Checkbox></span>
      </div>
      <span style={{ fontSize: 13 }}>Customization options</span>
      <div style={{ marginLeft: 30 }} className='customizeOptionsWrapper'>
        {customizeOptions.map((option, index) => {
          return <div className='customizeLabelWrapper' key={index}>
            <span style={{ fontSize: 13 }} ><Input className='editDishInfoModal' onChange={(e) => updateOptionValueById(customizeValue.id, index, "text", e.target.value)} value={option.text} /></span>
            <span style={{ fontSize: 13 }} ><InputNumber min={0} value={option?.extraPrice || 0} onChange={(e) => updateOptionValueById(customizeValue.id, index, "extraPrice", e)} /></span>
            <span style={{ fontSize: 13 }} ><Checkbox className='chefDishAvailabilityCheckbox' checked={option?.isSelected} onChange={(e) => updateOptionValueById(customizeValue.id, index, "isSelected", e.target.checked)} >Default</Checkbox></span>
          </div>
        })}
      </div>
      <Button size={8} style={{marginTop: 10}} onClick={() => onNewCustomizationOptionClick(customizeValue.id)} className='editDishModalFormButton addNewCustomizationOption' type="primary" >
        Add New Customization options
      </Button>
    </div>
  )
}

export default CustomizeComponent