import React, { useEffect, useState } from 'react'
import { Switch } from 'antd';
import { DISH_IMAGE } from '../../utils/constant'
import { useDispatch, useSelector } from 'react-redux';
import { getChefMenuList, updateDisbDetails } from '../../redux/actions/OrderAction';

const ChefDishCardComponent = ({ dish, showModal }) => {
    const dispatch = useDispatch();
    const updateDishData = useSelector(
        (state) => state.orders.updateDish
    );
    const [isavailable, setIsAvailable] =  useState(!dish?.unavailable);

    useEffect(() =>{
        setIsAvailable(!dish?.unavailable)
    },[dish?.unavailable])


    useEffect(() =>{
        if(updateDishData.success && !updateDishData.loading){
            const { _id, chefId } = dish;
            if(_id === updateDishData?.data?.dishId){
                dispatch(getChefMenuList({ chefId: chefId }))
            }
        }
    },[updateDishData.success])
    

    const onChange = (checked) => {
        setIsAvailable(checked)
        const newValue = { ...dish, unavailable: !checked };
        const { chefId, categoryId, _id } = dish
        const payload = {dishValue : newValue, chefId : chefId, categoryId: categoryId, dishId: _id};
        dispatch(updateDisbDetails(payload))
    }
   
    return (
        <div className='chefDishNameLabelWrapper'>
            <div className='dishImgBandBoxWrapper'>
                <div>
                    <span className='dish-img-band'>
                        <img className='dish-img-band-img' src={DISH_IMAGE + dish.img} />
                    </span>
                </div>
                <div><div className='chefDishNameLabel'>{dish.name}{!!dish?.customizeObj?.length && <span className='customisableLabel'>Customisable</span>}</div>
                    <span className='chefDishPrice'>Price: ₹{dish.price}</span>
                    <p className='dishDescriptionText'>
                        {dish.description}
                    </p></div>
            </div>
            <div>
                <div className='chefDishAvailability'>
                    <Switch size="small" className='chefDishAvailabilitySwitch' checked={isavailable} onChange={onChange} />
                    <div style={{ marginLeft: 8 }} className='chefDishPrice'>Available</div>
                </div>
                <div className='editDishBtnWrapper'>
                    <button className='editDishBtn' onClick={() => showModal(dish)}>
                        Edit dish
                    </button>
                </div>
                <div className='editDishBtnWrapper'>
                {/* <span className='customisableLabel'>Customisable</span> */}
                </div>
            </div>
        </div>
    )
}

export default ChefDishCardComponent