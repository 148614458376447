import * as React from "react";
const CallIcon = (props) => (
  <svg
    fill="#2B6EEA"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 15 15"
    xmlSpace="preserve"
    stroke="#2B6EEA"
    width={15}
    height={15}
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <g>
        <path d="M5.753 9.271c1.345 1.336 2.903 2.611 3.514 1.995 0.875 -0.882 1.414 -1.649 3.357 -0.1 1.942 1.55 0.459 2.593 -0.389 3.447 -0.978 0.986 -4.643 0.069 -8.275 -3.536C0.328 7.473 -0.617 3.815 0.362 2.829 1.21 1.975 2.242 0.484 3.805 2.415c1.564 1.93 0.801 2.475 -0.074 3.357 -0.611 0.616 0.676 2.163 2.022 3.499zm1.951 -6.371s-0.406 -0.063 -0.69 0.221c-0.293 0.293 -0.305 0.796 0.003 1.104 0.182 0.182 0.439 0.221 0.439 0.221 0.501 0.094 1.255 0.253 2.059 1.056 0.803 0.803 0.962 1.557 1.056 2.058 0 0 0.039 0.257 0.221 0.439 0.308 0.308 0.812 0.295 1.104 0.003 0.284 -0.284 0.221 -0.69 0.221 -0.69 -0.159 -1.014 -0.651 -2.074 -1.495 -2.918 -0.844 -0.844 -1.904 -1.336 -2.918 -1.495zm3.807 0.606c1.472 1.472 2.1 3.143 1.895 4.496 0 0 -0.071 0.429 0.209 0.709 0.315 0.315 0.815 0.297 1.11 0.002 0.19 -0.19 0.231 -0.498 0.231 -0.498 0.169 -1.17 -0.043 -3.523 -2.337 -5.817C10.325 0.104 7.971 -0.108 6.802 0.061c0 0 -0.308 0.041 -0.498 0.231 -0.295 0.295 -0.313 0.795 0.002 1.11 0.28 0.28 0.709 0.209 0.709 0.209 1.354 -0.204 3.024 0.423 4.496 1.895z" />
      </g>
    </g>
  </svg>
);
export default CallIcon;
