import * as React from "react";
const NonVeg = (props) => {
  const { fill = "red" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 48.000000 48.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path d="M60 240 l0 -180 180 0 180 0 0 180 0 180 -180 0 -180 0 0 -180z m340 0 l0 -160 -160 0 -160 0 0 160 0 160 160 0 160 0 0 -160z" />
        <path d="M190 333 c-25 -13 -39 -29 -49 -59 -13 -37 -12 -44 5 -80 40 -82 156 -80 190 3 40 95 -54 183 -146 136z" />
      </g>
    </svg>
  );
};
export default NonVeg;
