import { Button, Form, Input, TimePicker } from 'antd';
import "./pastExperiences.css"
import React, { useState } from 'react';
import { convertTimeFormat } from '../../utils/helper';
import DishImageUpload from '../OrderScreen/DishImageUpload';

const PastExperiencesForm = ({ index, time, onExperienceValueChange }) => {
    const [brandImg, setBrandImg] = useState("");
    const handleSetCollegeLogo = (logo) =>{
        const imgUrl = "https://cuirato-images.s3.ap-south-1.amazonaws.com/logo/" + logo
        setBrandImg(logo)
        onExperienceValueChange(time.id, "brandImg", imgUrl)
    }

    return <div style={{ flexDirection: "column" }} className='chefTimingWrapperBox' key={index}>
        <div style={{ width: "100%", gridTemplateColumns: "1fr 1fr", display: "grid", columnGap: 10 }} >
            <Form.Item
                className='editDishModalFormItem'
                label="College Logo"
            >
                <DishImageUpload newDishImg={brandImg} isChefLogo={true} setNewDishImg={handleSetCollegeLogo} />
            </Form.Item>

        </div>
        <div style={{ width: "100%", gridTemplateColumns: "1fr 1fr", display: "grid", columnGap: 10 }} >
            <Form.Item
                className='editDishModalFormItem'
                label="Brand name"
            >
                <Input className='editDishInfoModal' onChange={(e) => onExperienceValueChange(time.id, "brandName", e.target.value)} />
            </Form.Item>
            <Form.Item
                className='editDishModalFormItem'
                label="City"
            >
                <Input className='editDishInfoModal' onChange={(e) => onExperienceValueChange(time.id, "city", e.target.value)} />
            </Form.Item>
        </div>
        <div style={{ width: "100%", gridTemplateColumns: "1fr 1fr", display: "grid", columnGap: 10 }}><Form.Item
            className='editDishModalFormItem'
            label="Years"
        >
            <Input className='editDishInfoModal' onChange={(e) => onExperienceValueChange(time.id, "years", e.target.value)} />
        </Form.Item>
            <Form.Item
                className='editDishModalFormItem'
                label="Position"
            >
                <Input className='editDishInfoModal' onChange={(e) => onExperienceValueChange(time.id, "position", e.target.value)} />
            </Form.Item></div>
        <div style={{ width: "100%", gridTemplateColumns: "1fr 1fr", display: "grid", columnGap: 10 }}>
            <Form.Item
                className='editDishModalFormItem'
                label="Brand start year"
            >
                <Input className='editDishInfoModal' onChange={(e) => onExperienceValueChange(time.id, "brandStartYear", e.target.value)} />
            </Form.Item>
            <Form.Item
                className='editDishModalFormItem'
                label="Brand end year"
            >
                <Input className='editDishInfoModal' onChange={(e) => onExperienceValueChange(time.id, "brandEndYear", e.target.value)} />
            </Form.Item>
        </div>
    </div>
}


const PastExperiencesComponent = ({ onExperienceValueChange, chefExperiences, onNewExperiencesClick }) => {

    return (
        <div>
            <Form.Item
                className='editDishModalFormItem'
                label="Chef's experiences"
            >
                {chefExperiences.map((time, index) => {
                    return <PastExperiencesForm index={index} time={time} onExperienceValueChange={onExperienceValueChange} />
                })}
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "end" }} >
                <Button size={8} className='editDishModalFormButton addNewCustomizationOption' onClick={onNewExperiencesClick} type="primary">
                    Add New Experiences
                </Button>
            </div>
        </div>
    )
}

export default PastExperiencesComponent