import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client"; // Change import statement
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./redux/store";
import { ToastContainer } from "react-toastify";
import ReactPWAInstallProvider from "react-pwa-install";
import "react-toastify/dist/ReactToastify.css";

const initialState = {};

const store = configureStore(initialState);

// Use createRoot from "react-dom/client" instead of ReactDOM.render
const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactPWAInstallProvider enableLogging>
        <App />
      </ReactPWAInstallProvider>
      <ToastContainer />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
