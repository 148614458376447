import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import OrderDishNameInfo from "./OrderDishNameInfo";
import DownArrow from "../../assets/ReactIcon/DownArrow";
import UserInfoCard from "../UserInfoCard/Index";
import CallIcon from "../../assets/ReactIcon/CallIcon";
import TotalBillSummaryBox from "./TotalBillSummaryBox";
import { updateOrders } from "../../redux/actions/OrderAction";
import ChangeStatusSelectWrapper from "./ChangeStatusSelectWrapper";
import ComponentToPrint from "./ComponentToPrint/Index";
import "./orderViewCard.css";

const OrderViewCard = ({
  order = {},
  isDesktopView,
  onButtonClick,
  acceptButtonText,
  productNameUnderline,
  showOnlyLocationButton,
}) => {
  const {
    _id,
    orderRef,
    createdAt,
    status,
    scheduleTime,
    bookedDishes = [],
    billingAmount,
    deliveryFee,
    packagingFee,
    gstFee,
    address,
    userInfo = {},
    instructionText,
    assignedDeliveryId,
    paymentMode,
    deliveryTime
  } = order;
  const { houseNumber, area, landMark, location } = address || {};
  const userName = userInfo?.name;
  const phoneNumber = userInfo?.phoneNumber;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [deliveryPartner, setDeliveryPartner] = useState(assignedDeliveryId);
  const updateOrderReducer = useSelector((state) => state.orders.updateOrders);
  const deliveryAgentList = useSelector(
    (state) => state.orders.deliveryAgent.data
  );
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);

  const handleArrowClick = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      setContainerHeight(containerRef.current.scrollHeight);
    } else {
      setContainerHeight(0);
    }
  };

  const getDefaultStatus = () => {
    switch (status) {
      case "CREATED":
        return "NEW ORDER";
      case "CONFIRMED":
        return "PREPARING";
      case "READY":
        return "READY";
      case "PICKED_UP":
        return "ON THE WAY";
      case "DELIVERED":
        return "DELIVERED";
    }
  };

  const getStatusColor = () => {
    switch (status) {
      case "CREATED":
        return "#ffa500ad"; // Orange color code with alpha channel
      case "CONFIRMED":
        return "#ff4500"; // Red-Orange color
      case "READY":
        return "#32cd32"; // Lime Green color
      case "PICKED_UP":
        return "#039be5"; // Blue color
      case "DELIVERED":
        return "#008000"; // Green color
      default:
        return "#000000"; // Black color for unknown statuses
    }
  };

  const onConfirmPartnerClick = () => {
    dispatch(updateOrders({ id: _id, assignedDeliveryId: deliveryPartner }));
  };

  const deliveryAgentOptions =
    deliveryAgentList?.map((a) => ({ value: a._id, label: a.name })) || [];

  const deliveryAgentName =
    deliveryAgentOptions?.find((a) => a.value === deliveryPartner)?.label || "";

  const isButtonLoading =
    updateOrderReducer.loading && updateOrderReducer.orderId === _id;

  const getButtonStyle = () => {
    switch (acceptButtonText) {
      case "Order ready":
      case "Picked Up":
        return { background: "#2156CA" };

      default:
        break;
    }
  };

  const handleButtonClick = (id) => {
    onButtonClick(id);
  };

  const handleCallUserButtonClick = () => {
    window.open(`tel:+91${phoneNumber}`);
  }

  const scheduleTimeValue = new Date(scheduleTime);
  scheduleTimeValue.setHours(new Date(scheduleTime).getHours() - 5);
  scheduleTimeValue.setMinutes(new Date(scheduleTime).getMinutes() - 30);

  const estimatedDelivery = moment(createdAt);
  const updatedEstimatedDelivery = estimatedDelivery.add(deliveryTime, 'minutes');
  
  const isValidDeliveryPartnerNotSelected = !deliveryPartner || assignedDeliveryId

  return (
    <div className="orderViewCardWrapperChild">
      <div
        className={`kitchenCardWrapper ${isDesktopView ? "desktopKitchenCardWrapper" : ""
          }`}
      >
        <div>
          <div className="kitchenTimeInfoWrapper">
            <div>
              <span style={{ fontSize: 15 }}>ID: </span>
              <span className="boldLabel"> {orderRef || "N/A"}</span>
            </div>
            <div className="boldLabel">
              {scheduleTime && moment(scheduleTime).isValid() ? (
                <span className="scheduleTimeLabel">Schedule Time -{" "}
                  {moment(scheduleTimeValue).format("Do MMM")} -{" "}
                  {moment(scheduleTimeValue).format("h:mm A")}
                </span>
              ) : (
                <>
                  {moment(createdAt).format("Do MMM")} -{" "}
                  {moment(createdAt).format("h:mm A")}
                </>
              )}
            </div>
          </div>
          {isDesktopView && <hr />}
          <div style={{ marginTop: 5 }} className="kitchenTimeInfoWrapper">
            <div>
              <span className="boldLabel">{userName}</span>
            </div>
            <a className="userCallIconTag" onClick={handleCallUserButtonClick}>
              <CallIcon />
              <span
                className="fontSizeSmall"
                style={{ fontWeight: 600, marginLeft: 5 }}
              >
                Call
              </span>
            </a>
          </div>
          {isDesktopView && (
            <span className="fontSizeSmall">
              {houseNumber}
              {area && `, ${area}`}
              {landMark && `, ${landMark}`}
              {location?.locationName && `, ${location?.locationName}`}
            </span>
          )}
          {isDesktopView && <hr />}
          {isDesktopView && (
            <div>
              <span className="fontSizeMedium">Placed:</span>{" "}
              <span style={{ fontWeight: 500 }} className="fontSizeMedium">
                {moment(createdAt).format("Do MMM")} -{" "}
                {moment(createdAt).format("h:mm A")}
              </span>
            </div>
          )}
          <div className="orderStatusWrapper">
            <span
              style={{ background: getStatusColor() }}
              className="orderStatusLabel"
            >
              {getDefaultStatus()}
            </span>
            {moment(scheduleTime).isValid() ? <span className="boldLabel">Delivery at -{" "}
            {moment(scheduleTimeValue).format("Do MMM")} -{" "}
              {moment(scheduleTimeValue).format("h:mm A")}
            </span> : <span className="boldLabel">Delivery at -{" "}
            {moment(updatedEstimatedDelivery).format("Do MMM")} -{" "}
              {moment(updatedEstimatedDelivery).format("h:mm A")}
            </span>}
          </div>
          <div>
            <span className="fontSizeMedium">Total items:</span>{" "}
            <span style={{ fontWeight: 500 }} className="fontSizeMedium">
              {bookedDishes?.length}
            </span>
          </div>
        </div>
        <div style={isDesktopView ? { position: "relative", paddingBottom: 55 } : { position: "relative" }} >
          <div className="orderDishDetailsWrapper">
            {bookedDishes.map((dish) => (
              <OrderDishNameInfo
                key={dish._id}
                productNameUnderline={productNameUnderline}
                dish={dish}
              />
            ))}
          </div>
          {!!instructionText && (
            <div className="instructionTextBox">
              <img
                width={14}
                src="https://cdn-icons-png.flaticon.com/128/190/190411.png"
              />
              <span className="instructionTextLabel">{instructionText}</span>
            </div>
          )}
          <div
            // style={isDesktopView ? { marginBottom: 57 } : {}}
            className="totalBillWrapper"
          >
            <div className="totalBillLeftContainer">
              <span className="totalBillLabel">Total Bill</span>
              <span class="orderBillStatusLabel">{paymentMode === "online" || status === "DELIVERED" ? "PAID" : "PENDING"}</span>
              {true && (
                <button
                  onClick={handleArrowClick}
                  className={`transparentButton ${isOpen ? "open" : ""}`}
                >
                  <DownArrow />
                </button>
              )}
            </div>
            <div className="totalBillAmount">₹{billingAmount}</div>
          </div>
          {true && (
            <>
              <div
                className={`dropdown-container`}
                style={{ maxHeight: containerHeight }}
                ref={containerRef}
              >
                <TotalBillSummaryBox
                  deliveryFee={deliveryFee}
                  packagingFee={packagingFee}
                  gstFee={gstFee}
                  billingAmount={billingAmount}
                />
              </div>
              <UserInfoCard
                {...order}
                order={order}
                showPrintKot={true}
                showOnlyLocationButton={showOnlyLocationButton}
                assignedDeliveryId={assignedDeliveryId}
                deliveryAgentName={deliveryAgentName}
                createdAt={createdAt}
              />
              <ChangeStatusSelectWrapper
                assignedDeliveryId={assignedDeliveryId}
                deliveryPartner={deliveryPartner}
                setDeliveryPartner={setDeliveryPartner}
                deliveryAgentOptions={deliveryAgentOptions}
                deliveryAgentName={deliveryAgentName}
                onConfirmPartnerClick={onConfirmPartnerClick}
              />
            </>
          )}
          {acceptButtonText && (
            <ComponentToPrint
              order={order}
              isDesktopView={isDesktopView}
              handleButtonClick={handleButtonClick}
              isButtonLoading={isButtonLoading}
              getButtonStyle={getButtonStyle}
              acceptButtonText={acceptButtonText}
              isValidDeliveryPartnerNotSelected={isValidDeliveryPartnerNotSelected}
            />
          )}
        </div>
        {isDesktopView && (
          <div>
            <UserInfoCard
              {...order}
              order={order}
              showPrintKot={false}
              showOnlyLocationButton={showOnlyLocationButton}
              assignedDeliveryId={assignedDeliveryId}
              deliveryAgentName={deliveryAgentName}
              createdAt={createdAt}
            />
            <ChangeStatusSelectWrapper
              assignedDeliveryId={assignedDeliveryId}
              deliveryPartner={deliveryPartner}
              setDeliveryPartner={setDeliveryPartner}
              deliveryAgentOptions={deliveryAgentOptions}
              deliveryAgentName={deliveryAgentName}
              onConfirmPartnerClick={onConfirmPartnerClick}
            />
          </div>
        )}
      </div>

      {!isDesktopView && <div className="kitchenLine" />}
    </div>
  );
};

export default OrderViewCard;
