import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { DISH_IMAGE, DISH_IMAGE_STORY, LOGO_IMAGE } from '../../utils/constant';
import AWS from 'aws-sdk';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const DishStoryUploadComponent = (props) => {
  const { form, stories = [], setUpdatedStories } = props
  const fileListArray = stories.map((s) => ({
    uid: s._id,
    name: s.img,
    status: 'done',
    url: DISH_IMAGE + s.img,
  }))
  
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState(fileListArray);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => {
    const storiesList = newFileList.map((file) => ({
      img: file?.url?.includes("menu/thumbnail/") 
        ? file.url.split("menu/thumbnail/")[1] 
        : file.url,
      isActive: true,
      isDeleted: false
    }));
    
    setUpdatedStories(storiesList)
    setFileList(newFileList)
  };

  const customRequest = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      const S3_BUCKET = "cuirato-images";
      const REGION = "ap-south-1";

      AWS.config.update({
        accessKeyId: "AKIAZOY6NWS6HO7NR7PG",
        secretAccessKey: "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",
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });
      const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
      const fileName = randomNumber + file.name
      const params = {
        Bucket: S3_BUCKET,
        Key: `menu/thumbnail/${fileName}`,
        Body: file,
      };

      var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {

        })
        .promise();

      await upload.then((err, data) => {
        let tempFileList = [...fileList]
        const fileNameStr = fileName.slice(10)
        const foundIndex = tempFileList.findIndex(x => x.name === fileNameStr);
        const newObj = tempFileList[foundIndex];
        newObj.status = "done";
        newObj.url = fileName;
        tempFileList[foundIndex] = newObj;
        setFileList(tempFileList)
        const storiesList = tempFileList.map((file) =>({img : file?.url?.includes("menu/thumbnail/") 
        ? file.url.split("menu/thumbnail/")[1] 
        : file.url, isActive: true, isDeleted: false }));
        
        setUpdatedStories(storiesList)
        form.setFieldValue('stories', storiesList)
      });
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture-circle"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={customRequest}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default DishStoryUploadComponent;