import React from "react";
import { Button } from "antd";
import "./componentToPrint.css";

const ComponentToPrint = (props) => {
  const { order, isButtonLoading, isDesktopView, handleButtonClick, getButtonStyle, acceptButtonText, isValidDeliveryPartnerNotSelected } = props;
  const {
    _id,
    bookedDishes,
    instructionText,
    orderRef,
  } = order || {};

  const isAcceptText = acceptButtonText === "Accept"

  const handlePrint = () => {
    const printWindow = window.open(" ", " ", "width=1200,height=1200");
    printWindow.document.open();
    printWindow.document.write(`
    <div className="order-receipt" style="padding: 5px; margin-left: -8px; height: max-content; width: 260px; border: 1px solid #ccc; font-family: Arial, sans-serif;">
    <div className="header" style="text-align: center; font-size: 18px; font-weight: bold; margin-bottom: 10px;">Cuirato Order Receipt</div>
    <div className="order-item" style="display: flex; justify-content: space-between; margin-bottom: 5px;">
      <div className="item-name" style="flex-grow: 1;">Bill No: ${
        orderRef || "N/A"
      }</div>
    </div>
    <div>------------------------------------------------</div>
    ${bookedDishes
      .map(
        (item, index) => `
      <div className="order-item" style="display: flex; justify-content: space-between; margin-bottom: 5px;" key="${index}">
        <div className="item-name" style="flex-grow: 1; font-size: 18px; font-weight: bold;">${item?.dish?.name}${item?.quantity && `(${item?.quantity})`}</div>
        <div className="item-quantity" style="flex-shrink: 0; font-size: 18px; font-weight: bold;">
          ${item?.portion}
        </div>
      </div>
    `
      )
      .join("")}
      <div>------------------------------------------------</div>
     ${instructionText && `<div className="item-name" style="flex-grow: 1;">${instructionText}</div>`
      }
  </div>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
      <div
        onClick={() => {
          if(isAcceptText){
            // handlePrint();
          }
          handleButtonClick(_id);
        }}
        style={isDesktopView ? {position:"absolute"} : {}}
        className="acceptButtonWrapper"
      >
        <Button
          loading={isButtonLoading}
          style={getButtonStyle()}
          className="acceptButtonContent"
          disabled={isValidDeliveryPartnerNotSelected && acceptButtonText === "Picked Up"}
        >
          {acceptButtonText}
        </Button>
      </div>
  );
};

export default ComponentToPrint;
