import { Button, Form, Input, InputNumber, Modal } from 'antd'
import React, { useEffect } from 'react'
import { addKitchen, getKitchensList } from '../redux/actions/OrderAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const CreateKitchenModal = ({ isModalOpen, handleCancel }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const addKitchenState = useSelector((state) => state.orders.addKitchen);
    
    const onFinish = (values) => {
        const phoneRegex = /^\d{10}$/;
        if(!phoneRegex.test(values.phoneNumber)){
            toast.error("Please enter valid phone number")
            return
        }
        dispatch(addKitchen(values))
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
      };

    useEffect(() =>{
        if(addKitchenState.success){
            dispatch(getKitchensList());
            onReset()
            handleCancel()
        }
    },[addKitchenState.success])

    return (
        <Modal title="Create new kitchen" okButtonProps={{ className: "editDishModalWrapper" }} open={isModalOpen} onCancel={() =>{
            onReset()
            handleCancel()
        }}>
            <Form
                name="basic"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                style={{
                    maxWidth: 800,
                }}
                initialValues={{
                    remember: true,
                }}
                form={form}
                className='editDishModalForm'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    className='editDishModalFormItem'
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your name!',
                        },
                    ]}
                >
                    <Input className='editDishInfoModal' />
                </Form.Item>
                <Form.Item
                    className='editDishModalFormItem'
                    label="Phone number"
                    name="phoneNumber"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your phone number!',
                        },
                    ]}
                >
                    <InputNumber style={{ width: "100%"}} />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }} >
                <Form.Item
                style={{marginBottom: 0}}
                >
                    <Button  loading={addKitchenState?.loading} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                </div>
            </Form>
        </Modal>
    )
}

export default CreateKitchenModal