import * as React from "react";
const DishesIcon = (props) => (
  <svg
    fill="#000000"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20px"
    height="20px"
    viewBox="0 0 38.072 38.072"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g>
        <path d="M19.037 2.981c-8.867 0 -16.055 7.187 -16.055 16.055 0 6.892 4.352 12.753 10.451 15.029l0.228 -4.433c0 -3.213 -2.373 -2.182 -2.373 -4.431 0 -1.258 0.038 -3.883 0.038 -5.851 0 -5.249 2.228 -10.328 2.777 -11.46 0.55 -1.132 1.847 -2.366 1.861 -1.179 0 1.724 0.073 21.778 0.073 21.778h-0.004l0.256 6.35c0.895 0.155 1.808 0.251 2.747 0.251a15.883 15.883 0 0 0 3.347 -0.357l0.544 -10.503s-0.946 -0.311 -1.153 -0.411c-2.004 -0.883 -3.461 -3.485 -3.461 -6.858 0 -3.682 2.855 -6.854 5.617 -6.88H23.943c2.762 0.026 5.618 3.198 5.618 6.88 0 3.374 -1.458 5.973 -3.46 6.858 -0.015 0.007 -1.161 0.422 -1.161 0.422l0.495 9.516c5.68 -2.473 9.656 -8.13 9.656 -14.721 0 -8.866 -7.188 -16.054 -16.054 -16.054z" />
        <path d="M20.666 15.329c0.057 -0.234 0.134 -0.457 0.138 -0.46 0.024 -0.088 -0.022 -0.177 -0.116 -0.235 -0.091 -0.049 -0.202 -0.023 -0.253 0.055 0 0 -0.109 0.175 -0.268 0.433 -0.13 0.223 -0.322 0.578 -0.452 1.021 -0.114 0.388 -0.249 0.905 -0.309 1.481a6.937 6.937 0 0 0 -0.023 0.859c0.023 0.321 0.072 0.596 0.165 0.91 0.314 0.898 0.558 1.765 1.434 2.62 0.278 0.286 0.562 0.48 0.767 0.623l0.073 0.051c0.044 0.029 0.087 0.056 0.129 0.079 0.172 0.092 0.288 0.124 0.309 0.129 0.086 0.022 0.174 -0.02 0.212 -0.088 0.034 -0.073 0.009 -0.165 -0.063 -0.224 0 0 -0.1 -0.086 -0.228 -0.249 -0.088 -0.1 -0.183 -0.242 -0.295 -0.402 -0.078 -0.116 -0.171 -0.244 -0.256 -0.369 -0.428 -0.652 -0.674 -1.562 -1.014 -2.493 -0.066 -0.203 -0.122 -0.464 -0.142 -0.687a7.147 7.147 0 0 1 -0.041 -0.734c-0.009 -0.533 0.014 -0.991 0.06 -1.362 0.056 -0.42 0.098 -0.734 0.173 -0.957z" />
        <path d="M19.037 0C8.542 0 0 8.54 0 19.036 0 29.53 8.542 38.072 19.037 38.072c10.495 0 19.035 -8.542 19.035 -19.036C38.072 8.54 29.532 0 19.037 0zm0 36.652c-9.714 0 -17.618 -7.903 -17.618 -17.616 0 -9.714 7.904 -17.618 17.618 -17.618 9.713 0 17.618 7.904 17.618 17.618 0 9.713 -7.904 17.616 -17.618 17.616z" />
      </g>
    </g>
  </svg>
);
export default DishesIcon;
