import React from "react";
import "./orderViewCard.css";
import { Button, Select } from "antd";
import { useSelector } from "react-redux";

const ChangeStatusSelectWrapper = ({
  assignedDeliveryId,
  deliveryPartner,
  setDeliveryPartner,
  deliveryAgentOptions,
  deliveryAgentName,
  onConfirmPartnerClick,
}) => {
  if (assignedDeliveryId) {
    return <></>
    return (
      <div className="confirmPartnerButtonWrapper">
        <Button
          onClick={onConfirmPartnerClick}
          className="confirmPartnerButton"
          disabled={!deliveryPartner || assignedDeliveryId}
        >
          {assignedDeliveryId
            ? `Order Assigned to ${deliveryAgentName}`
            : "Confirm Partner"}
        </Button>
      </div>
    );
  }

  return (
    <div className="changeStatusSelectWrapper">
      <Select
        className="changeStatusSelect"
        style={{ height: 36 }}
        disabled={assignedDeliveryId}
        placeholder="Select Partner"
        value={deliveryPartner}
        onChange={(e) => setDeliveryPartner(e)}
        options={[...deliveryAgentOptions]}
      />
      <Button
        onClick={onConfirmPartnerClick}
        className="confirmPartnerButton"
        disabled={!deliveryPartner || assignedDeliveryId}
      >
        {assignedDeliveryId
          ? `Order Assigned to ${deliveryAgentName}`
          : "Confirm Partner"}
      </Button>
    </div>
  );
};

export default ChangeStatusSelectWrapper;
