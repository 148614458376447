import React from "react";
import NonVeg from "../../assets/ReactIcon/NonVeg";
import "./orderViewCard.css";

const OrderDishNameInfo = ({ dish, productNameUnderline }) => {
  const quantityTextLabel = dish?.customizeObj?.length ? dish?.customizeObj.map(item => item.text).join(' ').trim() : "";
  
  return (
    <div style={{ marginBottom: 4 }}>
      <div className="orderDishNameInfo">
        <div className="dishNameLabel">
          <span className="nonVegIcon">
            <NonVeg fill={dish?.dish?.type === "veg" ? "green" : "red"} />
          </span>
          <span style={{ fontSize: 15 }}>{dish?.portion}</span>
          <span style={productNameUnderline ? { fontSize: 13, height: 16 } : { fontSize: 13 }}>X</span>
          <span className="dishNameWithQuantity">
            <span className={productNameUnderline && "dotted-underline"}>{dish?.dish?.name}</span>{dish?.quantity && <span className="boldLabel">  Quantity: {dish?.quantity}</span>}
          </span>

        </div>
        <div className="boldLabel">
          <span style={{ fontSize: 13, marginRight: 2 }}>₹</span>
          {dish.dealPrice?.toFixed(2)}
        </div>
      </div>
      {!!quantityTextLabel && <span style={{fontSize:13,fontWeight:500, marginLeft:20}}>Customisable: {quantityTextLabel}</span>}
    </div>
  );
};

export default OrderDishNameInfo;
