import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Switch, Button, InputNumber, Select } from 'antd';
import "./chefDishesDrawer.css";
import { getChefMenuList, updateDisbDetails } from '../../redux/actions/OrderAction';
import { useDispatch, useSelector } from 'react-redux';
import CustomizeComponent from './CustomizeComponent';
import { removeIds } from '../../utils/helper';
import DishImageUpload from './DishImageUpload';
import DishStoryUploadComponent from './DishStoryUploadComponent';
import { toast } from 'react-toastify';

const { TextArea } = Input;

const EditDishModal = ({ handleCancel, isModalOpen, modalDishInfo, newDishClicked, chefCategories, chefDetails }) => {

    const [form] = Form.useForm();
    const [customizeObjValue, setCustomizeObjValue] = useState([])
    const [newDishImg, setNewDishImg] = useState("")
    const [updatedStories, setUpdatedStories] = useState([])

    const dispatch = useDispatch();
    const updateDishData = useSelector(
        (state) => state.orders.updateDish
    );

    const assignUniqueIds = (arr = []) => {
        arr.forEach((obj, index) => {
            obj.id = `obj_${index + 1}`;
            if (obj.options) {
                obj.options.forEach((opt, optIndex) => {
                    opt.id = `opt_${index + 1}_${optIndex + 1}`;
                    if (!opt.hasOwnProperty('extraPrice')) {
                        opt.extraPrice = 0; // Add default extraPrice if it doesn't exist
                    }
                });
            }
        });
        return arr;
    };

    const initialValues =  newDishClicked ? {} : { ...modalDishInfo, unavailable: !modalDishInfo.unavailable }

    useEffect(() => {
        if (isModalOpen && modalDishInfo?.customizeObj) {
            setCustomizeObjValue(assignUniqueIds(modalDishInfo?.customizeObj))
        }
        if(!newDishClicked){
            form.setFieldsValue(initialValues);
        }
    }, [isModalOpen])

    const categoryIdForm = Form.useWatch('categoryId', { form, preserve: true });
    const onFinish = (values) => {
        const newValue = { ...modalDishInfo, ...values, unavailable: !values.unavailable, customizeObj: removeIds(customizeObjValue) };
        if (newDishImg) {
            newValue.img = newDishImg;
        }
        newValue.stories = updatedStories || [];
        const { chefId, categoryId, _id } = modalDishInfo;
        if (!newValue?.img) {
            toast.error("Please upload dish image")
            return
        }
       
        if (!updatedStories?.length && !modalDishInfo?.stories?.length) {
            toast.error("Please upload dish story image")
            return
        }
        const payload = { dishValue: newValue, chefId: chefId, categoryId: categoryId, dishId: _id };
        
        if (!newDishClicked) {
            dispatch(updateDisbDetails(payload))
        } else {
            delete payload.dishId;
            payload.dishValue.chefId = chefDetails._id;
            payload.dishValue.categoryId = categoryIdForm;
            payload.dishValue.chefName = chefDetails.name;
            payload.dishValue.chefImg = chefDetails.profileImg;
            payload.chefId = chefDetails._id;
            payload.categoryId = categoryIdForm;
            dispatch(updateDisbDetails(payload))
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
    };

    useEffect(() => {
        if (updateDishData.success && !updateDishData.loading) {
            const { chefId } = modalDishInfo;
            const { chefId: chefIdSelector } = updateDishData?.data
            if (chefId) {
                dispatch(getChefMenuList({ chefId: chefId }))
            } else if (chefIdSelector) {
                dispatch(getChefMenuList({ chefId: chefIdSelector }))
            }
            setNewDishImg("")
            onReset()
            handleCancel()
        }
    }, [updateDishData.success])

    const onCustomizeObjValueChange = (id, field, newValue) => {
        const tempcustomizeObjValue = [...customizeObjValue]
        const itemIndex = tempcustomizeObjValue.findIndex(obj => obj.id === id);
        if (itemIndex !== -1) {
            // Check if the field exists in the object
            if (field in tempcustomizeObjValue[itemIndex]) {
                // Update the field with the new value
                tempcustomizeObjValue[itemIndex][field] = newValue;
                setCustomizeObjValue(tempcustomizeObjValue)
            } else {
                console.log(`Field ${field} does not exist in object with id ${id}`);
            }
        } else {
            console.log(`Object with id ${id} not found`);
        }
    }

    const onNewCustomizationOptionClick = (id) => {
        const tempcustomizeObjValue = [...customizeObjValue]
        const foundObj = tempcustomizeObjValue.find(obj => obj.id === id);
        if (foundObj) {
            foundObj.options.push({
                text: "",
                isSelected: false,
                extraPrice: 0
            });
            setCustomizeObjValue(tempcustomizeObjValue)
            console.log(`Added empty options to object with id ${id}`);
        } else {
            console.log(`Object with id ${id} not found`);
        }
    }

    const updateOptionValueById = (id, optionIndex, field, newValue) => {
        const tempcustomizeObjValue = [...customizeObjValue]
        const item = tempcustomizeObjValue.find(obj => obj.id === id);
        if (item && item.options && item.options[optionIndex]) {
            // Check if the field exists in the option
            if (field in item.options[optionIndex]) {
                if (field === 'isSelected' && newValue === true) {
                    item.options.forEach((option, index) => {
                        if (index !== optionIndex) {
                            option.isSelected = false;
                        }
                    });
                }
                // Update the field with the new value
                item.options[optionIndex][field] = newValue;
                setCustomizeObjValue(tempcustomizeObjValue)
                console.log(`Updated ${field} of option ${optionIndex} in object with id ${id} to ${newValue}`);
            } else {
                console.log(`Field ${field} does not exist in option ${optionIndex} of object with id ${id}`);
            }
        } else {
            console.log(`Object with id ${id} or option with index ${optionIndex} not found`);
        }
    }

    const onCustomizeClick = () => {
        const tempcustomizeObjValue = [...customizeObjValue]
        const emptyObject = {
            label: "",
            isOptional: false,
            options: [
                {
                    text: "",
                    isSelected: false,
                    extraPrice: 0,
                    id: `opt_${customizeObjValue.length + 1}_1`
                },
                {
                    text: "",
                    isSelected: false,
                    extraPrice: 0,
                    id: `opt_${customizeObjValue.length + 1}_2`
                }
            ],
            id: `obj_${customizeObjValue.length + 1}`
        };
        tempcustomizeObjValue.push(emptyObject);
        setCustomizeObjValue(tempcustomizeObjValue)
    }

    return (
        <Modal title={newDishClicked ? "Add dish details" : "Edit dish details"} width={800} okButtonProps={{ className: "editDishModalWrapper" }} visible={isModalOpen} onCancel={() => {
            handleCancel()
            setNewDishImg("")
            onReset()
        }} destroyOnClose >
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
                style={{ marginTop: 20 }}
                // initialValues={initialValues}
                className='editDishModalForm'
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item className='editDishModalFormItem' label="Dish Image" >
                    <DishImageUpload {...modalDishInfo} newDishImg={newDishImg} form={form} setNewDishImg={setNewDishImg} />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="categoryId" label="Category" rules={[{ required: true, message: 'Please Choose category!' }]} >
                    <Select
                        style={{ width: 300 }}
                        options={chefCategories}
                    />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="name" label="Dish Name" rules={[{ required: true, message: 'Please input your dish name!' }]} >
                    <Input className='editDishInfoModal' />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="description" label="Description" rules={[{ required: true, message: 'Please input your description!' }]} >
                    <TextArea rows={4} placeholder="Dish Description" />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="deliveryCost" label="Delivery Cost" rules={[{ required: true, message: 'Please input your delivery Cost!' }]} >
                    <InputNumber min={1} />
                </Form.Item>

                <Form.Item className='editDishModalFormItem' label="Customization">
                    <div className='CustomizeComponentWrapper'>
                        {customizeObjValue.map((value, index) => (<div key={index}><CustomizeComponent customizeValue={value} onNewCustomizationOptionClick={onNewCustomizationOptionClick} onCustomizeObjValueChange={onCustomizeObjValueChange} updateOptionValueById={updateOptionValueById} /></div>))}
                    </div>
                    <Button size={8} className='editDishModalFormButton addNewCustomizationOption' onClick={onCustomizeClick} type="primary">
                        Add New Customization
                    </Button>
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="fuelCost" label="Fuel Cost" rules={[{ required: true, message: 'Please input your Fuel Cost!' }]} >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="ingredientCost" label="Ingredient Cost" rules={[{ required: true, message: 'Please input your Ingredient Cost!' }]} >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="margin" label="Margin" rules={[{ required: true, message: 'Please input your Margin!' }]} >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="packagingCost" label="Packaging Cost" rules={[{ required: true, message: 'Please input your Packaging Cost!' }]} >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="price" label="Price" rules={[{ required: true, message: 'Please input your Price!' }]} >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="type" label="Type" rules={[{ required: true, message: 'Please input your Type!' }]} >
                    <Select
                        defaultValue="veg"
                        style={{ width: 90 }}
                        options={[
                            { value: "veg", label: 'Veg' },
                            { value: 'egg', label: 'Egg' },
                            { value: 'non veg', label: 'Non veg' },
                        ]}
                    />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' name="unavailable" label="Available">
                    <Switch size="small" defaultChecked={initialValues.unavailable} />
                </Form.Item>
                <Form.Item className='editDishModalFormItem' label="Dish Story">
                    <DishStoryUploadComponent {...modalDishInfo} form={form} setUpdatedStories={setUpdatedStories} />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }} >
                <Form.Item style={{marginBottom: 0}}>
                    <Button loading={updateDishData?.loading} className='editDishModalFormButton' type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

export default EditDishModal;
