import * as React from "react";
const CloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height="18px"
    id="Layer_17894"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 18 18"
    width="18px"
    xmlSpace="preserve"
    fill="#fff"
    {...props}
  >
    <path d="m17.328 15.121 -5.125 -5.144 5.137 -5.078a0.542 0.542 0 0 0 0 -0.766L15.879 2.664a0.538 0.538 0 0 0 -0.383 -0.156 0.555 0.555 0 0 0 -0.383 0.156L10 7.726 4.879 2.668a0.538 0.538 0 0 0 -0.383 -0.156 0.555 0.555 0 0 0 -0.383 0.156L2.656 4.137a0.542 0.542 0 0 0 0 0.766l5.137 5.078 -5.121 5.14a0.542 0.542 0 0 0 0 0.766l1.461 1.469a0.538 0.538 0 0 0 0.766 0l5.101 -5.126 5.106 5.121a0.538 0.538 0 0 0 0.766 0l1.461 -1.469a0.542 0.542 0 0 0 0.16 -0.383 0.561 0.561 0 0 0 -0.164 -0.379z" />
  </svg>
);
export default CloseIcon;
