import React, { useEffect } from "react";
import "./orderScreen.css";
import "./chefDishesDrawer.css";
import { Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getChefMenuList } from "../../redux/actions/OrderAction";
import ChefDishesComponent from "./ChefDishesComponent";
import KitchenTopBar from "./KitchenTopBar";

const ChefDishesDrawer = ({
    onClose,
    openDrawer
}) => {
    const dispatch = useDispatch();
    const chefList = useSelector(
        (state) => state.orders.chefsList.data
    );
    const chefDishesList = useSelector(
        (state) => state.orders.chefDishes.data
    );

    const kitchenId = localStorage.getItem("userId");
    const kitchenChefsList = chefList.filter((a) => a.kitchenId === kitchenId)

    const onChefClick = (id) => {
        dispatch(getChefMenuList({ chefId: id }))
    }

    const onChange = (key) => {
        const chefDishesListIds = Object.keys(chefDishesList)
        const newOpenedChef = key.filter((a) => !chefDishesListIds.includes(a));
        if (newOpenedChef.length) {
            onChefClick(newOpenedChef[0])
        }
    }

    useEffect(() => {
        if (kitchenChefsList?.length) {
            onChefClick(kitchenChefsList[0]._id)
        }
    }, [kitchenChefsList?.length])

    const chefItems = kitchenChefsList.map((chef, index) => ({
        key: chef._id,
        label: <div className="chefDishLabel">{`Chef ${chef.name}'s dishes`}</div>,
        children: <ChefDishesComponent chefDetails={chef} />,
    }))


    return (
        <div>
            <KitchenTopBar showStatusBar={false} isHomePage={false} />
            <div className="kitchenSettingDrawer">
                {!!kitchenChefsList.length && <Collapse className="ChefDishesDrawerCollapse" items={chefItems} defaultActiveKey={kitchenChefsList[0]._id} onChange={onChange} />}
            </div>
        </div>
    );
};

export default ChefDishesDrawer;
