import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import DishImageUpload from '../OrderScreen/DishImageUpload';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addCategoryMenu, getChefMenuList } from '../../redux/actions/OrderAction';

const AddCategoryForm = ({ index, time, onCategoryChange }) => {
  const [brandImg, setBrandImg] = useState("");
  const handleSetCollegeLogo = (logo) => {
    const imgUrl = "https://cuirato-images.s3.ap-south-1.amazonaws.com/menu/" + logo;
    setBrandImg(logo)
    onCategoryChange(time.id, "img", logo)
  }

  return <div style={{ flexDirection: "column" }} className='chefTimingWrapperBox' key={index}>
    <div style={{ width: "100%", gridTemplateColumns: "1fr 1fr", display: "grid", columnGap: 10 }} >
      <Form.Item
        className='editDishModalFormItem'
        label="Category Image"
      >
        <DishImageUpload newDishImg={brandImg} isMenuLogo={true} setNewDishImg={handleSetCollegeLogo} />
      </Form.Item>

    </div>
    <div style={{ width: "100%", gridTemplateColumns: "1fr 1fr", display: "grid", columnGap: 10 }} >
      <Form.Item
        className='editDishModalFormItem'
        label="Category name"
      >
        <Input className='editDishInfoModal' onChange={(e) => onCategoryChange(time.id, "name", e.target.value)} />
      </Form.Item>
      <Form.Item
        className='editDishModalFormItem'
        label="Color"
      >
        <Input className='editDishInfoModal' onChange={(e) => onCategoryChange(time.id, "color", e.target.value)} />
      </Form.Item>
    </div>
    <div style={{ width: "100%", gridTemplateColumns: "1fr 1fr", display: "grid", columnGap: 10 }}><Form.Item
      className='editDishModalFormItem'
      label="Cuisine"
    >
      <Input className='editDishInfoModal' onChange={(e) => onCategoryChange(time.id, "cuisine", e.target.value)} />
    </Form.Item></div>
  </div>
}

const AddCategoryModal = ({ isCategoryModalOpen, setIsCategoryModalOpen, chefDetails, combinedDishesArray, allchefCategoriesList }) => {
  const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
  const [form] = Form.useForm();
  const addCategoryState = useSelector((state) => state.orders.addCategory);
  
  const dispatch = useDispatch();

  const initialCategory = [{
    "id": randomNumber,
    "isActive": true,
    "name": "",
    "img": "",
    "rank": "",
    "color": "",
    "cuisine": "",
    "dishes": []
  }]
  const [category, setCategory] = useState([...initialCategory])

  const onNewCategoryClick = () => {
    setCategory([...category, {
      "id": Math.floor(Math.random() * 9000000000) + 1000000000,
      "isActive": true,
      "name": "",
      "img": "",
      "rank": "",
      "color": "",
      "cuisine": "",
      "dishes": []
    }])
  }

  const onCategoryChange = (id, field, newValue) => {
    const tempcategory = [...category]
    const itemIndex = tempcategory.findIndex(obj => obj.id === id);
    if (itemIndex !== -1) {
      // Check if the field exists in the object
      if (field in tempcategory[itemIndex]) {
        // Update the field with the new value
        tempcategory[itemIndex][field] = newValue;
        setCategory(tempcategory)
      } else {
        console.log(`Field ${field} does not exist in object with id ${id}`);
      }
    } else {
      console.log(`Object with id ${id} not found`);
    }
  }
  const onFinish = (values) => {
    const payload = {};


    const allValidExperiences = category.every((a) => a.name && a.img && a.color && a.cuisine)
    if (!allValidExperiences) {
      toast.error("Please enter all category details")
      return
    }
    const newCategory = category.map((a) => {
      const { id, ...rest } = a;
      return rest
    });

    if (!combinedDishesArray.length && !allchefCategoriesList.length) {
      //create new menu category
      payload.type = "CREATE";
      payload.chefId = chefDetails._id;
      payload.name = chefDetails.name;
      payload.cuisine = chefDetails.cuisine;
      payload.categories = newCategory;
      dispatch(addCategoryMenu(payload))
    } else {
      payload.type = "UPDATE";
      payload.categories = [...newCategory];
      payload.chefId = chefDetails._id;
      // payload._id = allchefCategoriesList[0].menuId;
      dispatch(addCategoryMenu(payload))
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onReset = () => {
    setCategory([{
      "id": Math.floor(Math.random() * 9000000000) + 1000000000,
      "isActive": true,
      "name": "",
      "img": "",
      "rank": "",
      "color": "",
      "cuisine": "",
      "dishes": []
    }])
    form.resetFields();
  };

  useEffect(() =>{
    if(addCategoryState.success){
      dispatch(getChefMenuList({ chefId: chefDetails._id }))
        onReset()
        setIsCategoryModalOpen(false)
    }

},[addCategoryState.success])


  return (
    <Modal title="Add Category" destroyOnClose width={800} open={isCategoryModalOpen} okButtonProps={{ className: "editDishModalWrapper" }} onOk={() => setIsCategoryModalOpen(false)} onCancel={() => setIsCategoryModalOpen(false)}>
      <Form
        name="basic"
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 22,
        }}
        style={{
          maxWidth: 1400,
        }}
        initialValues={{
          remember: true,
        }}
        form={form}
        className='editDishModalForm'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {category.map((time, index) => {
          return <AddCategoryForm index={index} time={time} onCategoryChange={onCategoryChange} />
        })}
        <div style={{ display: "flex", justifyContent: "start" }} >
          <Button size={8} className='editDishModalFormButton addNewCustomizationOption' onClick={onNewCategoryClick} type="primary">
            Add Category
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "end", marginTop: 10 }} >
          <Form.Item style={{marginBottom: 0}} >
            <Button style={{marginLeft: 14}} className='editDishModalFormButton' type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default AddCategoryModal