export const MSG91_SMS_TEMPLATE_ID = "61eff73341b08647fb7b47f8";

export const MSG91_AUTH_KEY = "371349A3i17DHDg61cefebfP1";

export const DISH_IMAGE = "https://cuirato-images.s3.ap-south-1.amazonaws.com/menu/thumbnail/";

export const LOGO_IMAGE = "https://cuirato-images.s3.ap-south-1.amazonaws.com/logo/";

export const DISH_IMAGE_STORY = "https://cuirato-images.s3.ap-south-1.amazonaws.com/menu/";

export const allEmptyObjects = (array) => {
    return array.every(obj => Object.values(obj).every(value => value === ""));
}