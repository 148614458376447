import Cookies from "js-cookie";
import moment from "moment";
import { sortByDateOrder } from "../../utils/helper";

export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";

export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_ERROR = "SEND_OTP_ERROR";

export const ADD_DRIVER_REQUEST = "ADD_DRIVER_REQUEST";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_ERROR = "ADD_DRIVER_ERROR";

export const ADD_KITCHEN_REQUEST = "ADD_KITCHEN_REQUEST";
export const ADD_KITCHEN_SUCCESS = "ADD_KITCHEN_SUCCESS";
export const ADD_KITCHEN_ERROR = "ADD_KITCHEN_ERROR";

export const ADD_CHEF_REQUEST = "ADD_CHEF_REQUEST";
export const ADD_CHEF_SUCCESS = "ADD_CHEF_SUCCESS";
export const ADD_CHEF_ERROR = "ADD_CHEF_ERROR";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";

export const UPDATE_ORDERS_REQUEST = "UPDATE_ORDERS_REQUEST";
export const UPDATE_ORDERS_SUCCESS = "UPDATE_ORDERS_SUCCESS";
export const UPDATE_ORDERS_ERROR = "UPDATE_ORDERS_ERROR";

export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";

export const GET_DELIVERY_AGENT_REQUEST = "GET_DELIVERY_AGENT_REQUEST";
export const GET_DELIVERY_AGENT_SUCCESS = "GET_DELIVERY_AGENT_SUCCESS";
export const GET_DELIVERY_AGENT_ERROR = "GET_DELIVERY_AGENT_ERROR";

export const GET_KITCHENS_LIST_REQUEST = "GET_KITCHENS_LIST_REQUEST";
export const GET_KITCHENS_LIST_SUCCESS = "GET_KITCHENS_LIST_SUCCESS";
export const GET_KITCHENS_LIST_ERROR = "GET_KITCHENS_LIST_ERROR";

export const GET_CHEF_MENU_REQUEST = "GET_CHEF_MENU_REQUEST";
export const GET_CHEF_MENU_SUCCESS = "GET_CHEF_MENU_SUCCESS";
export const GET_CHEF_MENU_ERROR = "GET_CHEF_MENU_ERROR";

export const UPDATE_DISH_REQUEST = "UPDATE_DISH_REQUEST";
export const UPDATE_DISH_SUCCESS = "UPDATE_DISH_SUCCESS";
export const UPDATE_DISH_ERROR = "UPDATE_DISH_ERROR";

export const GET_CHEFS_LIST_REQUEST = "GET_CHEFS_LIST_REQUEST";
export const GET_CHEFS_LIST_SUCCESS = "GET_CHEFS_LIST_SUCCESS";
export const GET_CHEFS_LIST_ERROR = "GET_CHEFS_LIST_ERROR";

export const UPDATE_CHEFS_DETAIL_REQUEST = "UPDATE_CHEFS_DETAIL_REQUEST";
export const UPDATE_CHEFS_DETAIL_SUCCESS = "UPDATE_CHEFS_DETAIL_SUCCESS";
export const UPDATE_CHEFS_DETAIL_ERROR = "UPDATE_CHEFS_DETAIL_ERROR";

export const UPDATE_DELIVERY_AGENT_REQUEST = "UPDATE_DELIVERY_AGENT_REQUEST";
export const UPDATE_DELIVERY_AGENT_SUCCESS = "UPDATE_DELIVERY_AGENT_SUCCESS";
export const UPDATE_DELIVERY_AGENT_ERROR = "UPDATE_DELIVERY_AGENT_ERROR";

export const UPDATE_KITCHEN_REQUEST = "UPDATE_KITCHEN_REQUEST";
export const UPDATE_KITCHEN_SUCCESS = "UPDATE_KITCHEN_SUCCESS";
export const UPDATE_KITCHEN_ERROR = "UPDATE_KITCHEN_ERROR";

export const POST_LIVE_LOCATION_REQUEST = "POST_LIVE_LOCATION_REQUEST";
export const POST_LIVE_LOCATION_SUCCESS = "POST_LIVE_LOCATION_SUCCESS";
export const POST_LIVE_LOCATION_ERROR = "POST_LIVE_LOCATION_ERROR";

export const RESET_BLOCK_ORDER = "RESET_BLOCK_ORDER";

export const RESET_FLAGS_ORDER = "RESET_FLAGS_ORDER";

const liveLocationStateString = localStorage.getItem('liveLocationState');
const liveLocationState = !!liveLocationStateString && JSON.parse(liveLocationStateString);

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  orders: {
    ...block,
    data: [],
    pendingOrders: [],
    confirmedOrders: [],
    readyOrders: [],
    pickedUpOrders: [],
  },
  sendOtp: { ...block },
  addDriver: { ...block },
  addKitchen: { ...block },
  addChef: { ...block },
  addCategory: { ...block },
  updateOrders: { ...block, orderId: "" },
  userInfo: { ...block, data: {} },
  deliveryAgent: { ...block, data: [] },
  kitchensList: { ...block, data: [] },
  chefDishes: { ...block, data: {} },
  chefCategoriesList: { ...block, data: {} },
  updateDish: { ...block, data: {} },
  chefsList: { ...block, data: [] },
  updateChefDetails: { ...block },
  updateKitchen: { ...block },
  updateDeliveryAgent: { ...block },
  liveLocation: liveLocationState || { ...block },
};

export const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return { ...state, orders: { ...state.orders, loading: true } };
    case GET_ORDERS_SUCCESS:
      // const convertedData = sortByDateOrder(action?.response?.data);
      const filteredData = action?.response?.data.filter((o) => o.status !== "DELIVERED")
      const convertedData = filteredData.map((item) => ({
        ...item,
        createdMoment: moment(item.createdAt),
      }));

      convertedData.sort((a, b) =>
        b.createdMoment.isBefore(a.createdMoment) ? -1 : 1
      );
      return {
        ...state,
        orders: {
          ...state.orders,
          data: convertedData,
          pendingOrders: convertedData.filter((o) => o.status === "CREATED"),
          confirmedOrders: convertedData.filter(
            (o) => o.status === "CONFIRMED"
          ),
          readyOrders: convertedData.filter((o) => o.status === "READY"),
          pickedUpOrders: convertedData.filter((o) => ["PICKED_UP", "DELIVERED"].includes(o.status)),
          rowData: [...filteredData],
          loading: false,
          success: true,
          error: "",
        },
      };
    case GET_ORDERS_ERROR:
      return {
        ...state,
        orders: { ...state.orders, loading: false, error: action.error },
      };

    case SEND_OTP_REQUEST:
      return { ...state, sendOtp: { ...state.sendOtp, loading: true } };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        sendOtp: {
          ...state.sendOtp,
          data: action?.response.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case SEND_OTP_ERROR:
      return {
        ...state,
        sendOtp: { ...state.sendOtp, loading: false, error: action.error },
      };

    case ADD_DRIVER_REQUEST:
      return { ...state, addDriver: { ...state.addDriver, loading: true } };
    case ADD_DRIVER_SUCCESS:
      return {
        ...state,
        addDriver: {
          ...state.addDriver,
          data: action?.response.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case ADD_DRIVER_ERROR:
      return {
        ...state,
        addDriver: { ...state.addDriver, loading: false, error: action.error },
      };

    case ADD_KITCHEN_REQUEST:
      return { ...state, addKitchen: { ...state.addKitchen, loading: true } };
    case ADD_KITCHEN_SUCCESS:
      return {
        ...state,
        addKitchen: {
          ...state.addKitchen,
          data: action?.response.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case ADD_KITCHEN_ERROR:
      return {
        ...state,
        addKitchen: { ...state.addKitchen, loading: false, error: action.error },
      };

    case ADD_CHEF_REQUEST:
      return { ...state, addChef: { ...state.addChef, loading: true } };
    case ADD_CHEF_SUCCESS:
      return {
        ...state,
        addChef: {
          ...state.addChef,
          data: action?.response.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case ADD_CHEF_ERROR:
      return {
        ...state,
        addChef: { ...state.addChef, loading: false, error: action.error },
      };

    case ADD_CATEGORY_REQUEST:
      return { ...state, addCategory: { ...state.addCategory, loading: true, success: false } };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        addCategory: {
          ...state.addCategory,
          data: action?.response.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case ADD_CATEGORY_ERROR:
      return {
        ...state,
        addCategory: { ...state.addCategory, loading: false, error: action.error },
      };

    case UPDATE_ORDERS_REQUEST:
      return {
        ...state,
        updateOrders: {
          ...state.updateOrders,
          orderId: action.payload.id,
          loading: true,
        },
      };
    case UPDATE_ORDERS_SUCCESS:
      return {
        ...state,
        updateOrders: {
          ...state.updateOrders,
          data: action?.response.data,
          orderId: "",
          loading: false,
          success: true,
          error: "",
        },
      };
    case UPDATE_ORDERS_ERROR:
      return {
        ...state,
        updateOrders: {
          ...state.updateOrders,
          orderId: "",
          loading: false,
          error: action.error,
        },
      };

    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        userInfo: { ...state.userInfo, loading: true },
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: action?.response?.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case GET_USER_INFO_ERROR:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          error: action.error,
        },
      };

    case GET_DELIVERY_AGENT_REQUEST:
      return {
        ...state,
        deliveryAgent: { ...state.deliveryAgent, loading: true },
      };
    case GET_DELIVERY_AGENT_SUCCESS:
      const updatedData = action?.response?.data?.map((user) => ({
        ...user,
        type: "DELIVERY",
      }));
      return {
        ...state,
        deliveryAgent: {
          ...state.deliveryAgent,
          data: updatedData,
          loading: false,
          success: true,
          error: "",
        },
      };
    case GET_DELIVERY_AGENT_ERROR:
      return {
        ...state,
        deliveryAgent: {
          ...state.deliveryAgent,
          loading: false,
          error: action.error,
        },
      };

    case GET_KITCHENS_LIST_REQUEST:
      return {
        ...state,
        kitchensList: { ...state.kitchensList, loading: true },
        addKitchen: { ...state.addKitchen, success: false }
      };
    case GET_KITCHENS_LIST_SUCCESS:
      const updatedKitchenData = action?.response?.data?.map((user) => ({
        ...user,
        type: "KITCHENS",
      }));
      return {
        ...state,
        kitchensList: {
          ...state.kitchensList,
          data: updatedKitchenData,
          loading: false,
          success: true,
          error: "",
        },
      };
    case GET_KITCHENS_LIST_ERROR:
      return {
        ...state,
        kitchensList: {
          ...state.kitchensList,
          loading: false,
          error: action.error,
        },
      };

    case GET_CHEF_MENU_REQUEST:
      return {
        ...state,
        chefDishes: { ...state.chefDishes, loading: true },
      };
    case GET_CHEF_MENU_SUCCESS:
      const { categories, allCategoriesList } = action?.response;
      const chefDishesList = { ...state.chefDishes.data };
      const chefCategoriesList = { ...state.chefCategoriesList.data };
      const chefId = action?.response?.chefId
      chefDishesList[chefId] = categories;
      chefCategoriesList[chefId] = allCategoriesList;
      return {
        ...state,
        chefDishes: {
          ...state.chefDishes,
          data: chefDishesList,
          loading: false,
          success: true,
          error: "",
        },
        chefCategoriesList: {
          ...state.chefCategoriesList,
          data: chefCategoriesList,
          loading: false,
          success: true,
          error: "",
        },
      };
    case GET_CHEF_MENU_ERROR:
      return {
        ...state,
        chefDishes: {
          ...state.chefDishes,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_DISH_REQUEST:
      return {
        ...state,
        updateDish: { ...state.updateDish, loading: true, success: false },
      };
    case UPDATE_DISH_SUCCESS:
      return {
        ...state,
        updateDish: {
          ...state.updateDish,
          data: action?.response,
          loading: false,
          success: true,
          error: "",
        },
      };
    case UPDATE_DISH_ERROR:
      return {
        ...state,
        updateDish: {
          ...state.updateDish,
          loading: false,
          error: action.error,
        },
      };

    case GET_CHEFS_LIST_REQUEST:
      return {
        ...state,
        chefsList: { ...state.chefsList, loading: true },
      };
    case GET_CHEFS_LIST_SUCCESS:
      return {
        ...state,
        chefsList: {
          ...state.chefsList,
          data: action?.response?.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case GET_CHEFS_LIST_ERROR:
      return {
        ...state,
        chefsList: {
          ...state.chefsList,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_CHEFS_DETAIL_REQUEST:
      return {
        ...state,
        updateChefDetails: { ...state.updateChefDetails, loading: true },
      };
    case UPDATE_CHEFS_DETAIL_SUCCESS:
      return {
        ...state,
        updateChefDetails: {
          ...state.updateChefDetails,
          loading: false,
          success: true,
          error: "",
        },
      };
    case UPDATE_CHEFS_DETAIL_ERROR:
      return {
        ...state,
        updateChefDetails: {
          ...state.updateChefDetails,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_DELIVERY_AGENT_REQUEST:
      return {
        ...state,
        updateDeliveryAgent: { ...state.updateDeliveryAgent, loading: true },
      };
    case UPDATE_DELIVERY_AGENT_SUCCESS:
      return {
        ...state,
        updateDeliveryAgent: {
          ...state.updateDeliveryAgent,
          data: action?.response?.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case UPDATE_DELIVERY_AGENT_ERROR:
      return {
        ...state,
        updateDeliveryAgent: {
          ...state.updateDeliveryAgent,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_KITCHEN_REQUEST:
      return {
        ...state,
        updateKitchen: { ...state.updateKitchen, loading: true },
      };
    case UPDATE_KITCHEN_SUCCESS:
      return {
        ...state,
        updateKitchen: {
          ...state.updateKitchen,
          data: action?.response?.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case UPDATE_KITCHEN_ERROR:
      return {
        ...state,
        updateKitchen: {
          ...state.updateKitchen,
          loading: false,
          error: action.error,
        },
      };


    case POST_LIVE_LOCATION_REQUEST:
      return {
        ...state,
        liveLocation: { ...state.liveLocation, loading: true },
      };
    case POST_LIVE_LOCATION_SUCCESS:
      const liveLocationStateString = JSON.stringify(action?.response?.data);
      localStorage.setItem('liveLocationState', liveLocationStateString);
      return {
        ...state,
        liveLocation: {
          ...state.liveLocation,
          data: action?.response?.data,
          loading: false,
          success: true,
          error: "",
        },
      };
    case POST_LIVE_LOCATION_ERROR:
      return {
        ...state,
        liveLocation: {
          ...state.liveLocation,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_ORDER:
      return {
        ...state,
        [action.payload.blockType]: {
          ...state[action.payload.blockType],
          ...initialState[action.payload.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_ORDER:
      return {
        ...state,
        [action.payload.blockType]: {
          ...state[action.payload.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
