import { Form, TimePicker } from 'antd';
import "./chefScheduleTimePicker.css"
import React, { useState } from 'react';
import { convertTimeFormat } from '../../utils/helper';


const ChefScheduleTimePicker = ({ form, initialChefSchedule, chefSchedule, setChefSchedule }) => {

    const onChange = (id, key, timeString) => {
        const index = chefSchedule.findIndex((a) => a._id === id);
        const tempChefSchedule = [...chefSchedule];
        tempChefSchedule[index][key] = convertTimeFormat(timeString);
        form.setFieldValue('schedules', tempChefSchedule)
        setChefSchedule(tempChefSchedule)
    };

    const format = 'HH:mm';
    return (
        <div>
            <Form.Item
                className='editDishModalFormItem'
                label="Chef's timing"
                name="schedules"
            >
                {initialChefSchedule.map((time) => {
                    return <div className='chefTimingWrapperBox' key={time._id}>
                        <span className='chefTimingWrapper'>{time.dayOfWeek}</span>
                        <div>
                            <TimePicker className='scheduleTimePicker' style={{ width: 100, marginRight: 10 }} onChange={(t, timeString) => {
                                onChange(time._id, "startTime", timeString)
                            }} format={format} />
                            <TimePicker className='scheduleTimePicker' style={{ width: 100 }} onChange={(t, timeString) => {
                                onChange(time._id, "endTime", timeString)
                            }} format={format} />
                            <TimePicker className='scheduleTimePicker' style={{ width: 100, marginRight: 10, marginLeft: 10 }} onChange={(t, timeString) => {
                                onChange(time._id, "startTime1", timeString)
                            }} format={format} />
                            <TimePicker className='scheduleTimePicker' style={{ width: 100 }} onChange={(t, timeString) => {
                                onChange(time._id, "endTime1", timeString)
                            }} format={format} />
                        </div>
                    </div>
                })}
            </Form.Item>
        </div>
    )
}

export default ChefScheduleTimePicker