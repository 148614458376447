import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addChef, getChefsList } from '../../redux/actions/OrderAction';
import DishImageUpload from '../OrderScreen/DishImageUpload';
import ChefScheduleTimePicker from '../ChefScheduleTimePicker/Index';
import QualificationsFormComponent from '../QualificationsFormComponent/Index';
import PastExperiencesComponent from '../PastExperiencesComponent/Index';
import { allEmptyObjects } from '../../utils/constant';
import "./createChefModal.css"

const initialChefSchedule = [
    {
        "_id": "schedule1_id",
        "dayOfWeek": "Sunday",
        "startTime": "",
        "endTime": "",
        "startTime1": "",
        "endTime1": "",
        "isAvailable": true,
        "online": true
    },
    {
        "_id": "schedule2_id",
        "dayOfWeek": "Monday",
        "startTime": "",
        "endTime": "",
        "startTime1": "",
        "endTime1": "",
        "isAvailable": true,
        "online": true
    },
    {
        "_id": "schedule3_id",
        "dayOfWeek": "Tuesday",
        "startTime": "",
        "endTime": "",
        "startTime1": "",
        "endTime1": "",
        "isAvailable": false,
        "online": false
    },
    {
        "_id": "schedule4_id",
        "dayOfWeek": "Wednesday",
        "startTime": "",
        "endTime": "",
        "startTime1": "",
        "endTime1": "",
        "isAvailable": true,
        "online": true
    },
    {
        "_id": "schedule5_id",
        "dayOfWeek": "Thursday",
        "startTime": "",
        "endTime": "",
        "startTime1": "",
        "endTime1": "",
        "isAvailable": true,
        "online": true
    },
    {
        "_id": "schedule6_id",
        "dayOfWeek": "Friday",
        "startTime": "",
        "endTime": "",
        "startTime1": "",
        "endTime1": "",
        "isAvailable": true,
        "online": false
    },
    {
        "_id": "schedule7_id",
        "dayOfWeek": "Saturday",
        "startTime": "",
        "endTime": "",
        "startTime1": "",
        "endTime1": "",
        "isAvailable": true,
        "online": false
    }
]
const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;

const initialChefQualification = [{
    "id": randomNumber,
    "degree": "",
    "college": "",
    "collegeLogo": "",
    "collegeCity": "",
    "collegePosition": "",
    "collegeStartYear": "",
    "collegeEndYear": ""
}]

const initialChefExperiences = [{
    "id": randomNumber,
    "brandName": "",
    "brandImg": "",
    "city": "",
    "years": "",
    "position": "",
    "brandStartYear": "",
    "brandEndYear": ""
}]



const CreateChefModal = ({ isModalOpen, handleCancel }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const chefList = useSelector(
        (state) => state.orders.chefsList.data
    );
    const [liveLocation, setLiveLocation] = useState(null);
    const [newProfileImg, setNewProfileImg] = useState("");
    const [chefSchedule, setChefSchedule] = useState([...initialChefSchedule])
    const [chefQualification, setChefQualification] = useState([])
    const [chefExperiences, setChefExperiences] = useState([...initialChefExperiences])
    const addChefState = useSelector((state) => state.orders.addChef);
    const kitchensListList = useSelector(
        (state) => state.orders.kitchensList.data
    );

    const kitchenListOptions =
        kitchensListList.map((a) => ({ value: a._id, label: a.name })) || [];

    const onNewQualificationClick = () => {
        setChefQualification([...chefQualification, {
            "id": Math.floor(Math.random() * 9000000000) + 1000000000,
            "degree": "",
            "college": "",
            "collegeLogo": "",
            "collegeCity": "",
            "collegePosition": "",
            "collegeStartYear": "",
            "collegeEndYear": ""
        }])
    }

    const onNewExperiencesClick = () => {
        setChefExperiences([...chefExperiences, {
            "id": Math.floor(Math.random() * 9000000000) + 1000000000,
            "brandName": "",
            "brandImg": "",
            "city": "",
            "years": "",
            "position": "",
            "brandStartYear": "",
            "brandEndYear": ""
        }])
    }


    useEffect(() => {
        // Get the user's current location using the Geolocation API
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLiveLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error getting live location:", error);
                }
            );
        }

    }, []);

    const onValueChange = (id, field, newValue) => {

        const tempchefQualification = [...chefQualification]
        const itemIndex = tempchefQualification.findIndex(obj => obj.id === id);
        if (itemIndex !== -1) {
            // Check if the field exists in the object
            if (field in tempchefQualification[itemIndex]) {
                // Update the field with the new value
                tempchefQualification[itemIndex][field] = newValue;
                setChefQualification(tempchefQualification)
            } else {
                console.log(`Field ${field} does not exist in object with id ${id}`);
            }
        } else {
            console.log(`Object with id ${id} not found`);
        }
    }
    
    const onExperienceValueChange = (id, field, newValue) => {
        const tempchefExperiences = [...chefExperiences]
        const itemIndex = tempchefExperiences.findIndex(obj => obj.id === id);
        if (itemIndex !== -1) {
            // Check if the field exists in the object
            if (field in tempchefExperiences[itemIndex]) {
                // Update the field with the new value
                tempchefExperiences[itemIndex][field] = newValue;
                setChefExperiences(tempchefExperiences)
            } else {
                console.log(`Field ${field} does not exist in object with id ${id}`);
            }
        } else {
            console.log(`Object with id ${id} not found`);
        }
    }

    const updateAvailability = (scheduleArray) => {
        const updatedArray = [...scheduleArray].map(schedule => {
          if ((!!schedule.startTime && !!schedule.endTime) || (!!schedule.startTime1 && !!schedule.endTime1)) {
            schedule.isAvailable = true;
          } else {
            schedule.isAvailable = false;
          }
          return schedule;
        });
        return updatedArray;
      };

    const onFinish = (values) => {
        if (!newProfileImg) {
            toast.error("Please upload chef image")
            return
        }
        // const allValidQualification = chefQualification.every((a) => a.degree && a.college && a.collegeLogo && a.collegeCity && a.collegePosition && a.collegeStartYear && a.collegeEndYear)
        // const allValidExperiences = chefExperiences.every((a) => a.brandName && a.brandImg && a.city && a.years && a.position && a.brandStartYear && a.brandEndYear)
        
        // if (!allValidQualification) {
        //     toast.error("Please enter all qualification details")
        //     return
        // }
        // if (!allValidExperiences) {
        //     toast.error("Please enter all experiences details")
        //     return
        // }
        const payload = { ...values };
        payload.isMenuActive = true;
        payload.isActive = true;
        payload.listRank = `${chefList.length}`;
        payload.isSwitchOff = false;
        payload.chefLocation = { lat: values.lat, lng: values.lng };
        delete payload.lat;
        delete payload.lng;
        payload.profileImg = newProfileImg;
        payload.chefImgTh = newProfileImg;
        // payload.qualifications = chefQualification.map((a) => {
        //     const { id, ...rest } = a;
        //     return rest
        // });
        payload.qualifications = [];
        const newChefExperiences = chefExperiences.map((a) => {
            const { id, ...rest } = a;
            return rest
        });
        payload.pastExperiences = allEmptyObjects(newChefExperiences) ? [] :  newChefExperiences
        payload.schedules = updateAvailability(chefSchedule);
        dispatch(addChef(payload))
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
    };

    useEffect(() => {
        if (addChefState.success) {
            onReset()
            handleCancel()
            dispatch(getChefsList());
        }

    }, [addChefState.success])

    return (
        <Modal width={800} title="Create new chef" okButtonProps={{ className: "editDishModalWrapper" }} open={isModalOpen} onCancel={() => {
            onReset()
            handleCancel()
        }}>
            <Form
                name="basic"
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 22,
                }}
                style={{
                    maxWidth: 1000,
                }}
                initialValues={{
                    remember: true,
                }}
                form={form}
                className='editDishModalForm'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    className='editDishModalFormItem'
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your name!',
                        },
                    ]}
                >
                    <Input className='editDishInfoModal' />
                </Form.Item>
                <Form.Item
                    className='editDishModalFormItem'
                    label="Profile Image"
                    name="profileImg"
                    rules={[
                        {
                            required: true,
                            message: 'Please upload chef image!',
                        },
                    ]}
                >
                    <DishImageUpload newDishImg={newProfileImg} form={form} setNewDishImg={setNewProfileImg} />
                </Form.Item>
                <Form.Item
                    className='editDishModalFormItem'
                    label="Cuisine"
                    name="cuisine"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your cuisine!',
                        },
                    ]}
                >
                    <Input className='editDishInfoModal' />
                </Form.Item>
                <Form.Item
                    className='editDishModalFormItem'
                    label="Label 1"
                    name="label1"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Label 1!',
                        },
                    ]}
                >
                    <Input className='editDishInfoModal' />
                </Form.Item>
                <Form.Item
                    className='editDishModalFormItem'
                    label="Label 2"
                    name="label2"
                    rules={[
                        {
                            required: true,
                            message: `Please enter Label 2`,
                        },
                    ]}
                >
                    <Input className='editDishInfoModal' />
                </Form.Item>
                <Form.Item
                    className='editDishModalFormItem'
                    label="Label 3"
                    name="label3"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Label 3!',
                        },
                    ]}
                >
                    <Input className='editDishInfoModal' />
                </Form.Item>
                <Form.Item
                    className='editDishModalFormItem'
                    label="Kitchen"
                    name="kitchenId"
                    rules={[
                        {
                            required: true,
                            message: 'Please select chef kitchen!',
                        },
                    ]}
                >
                    <Select
                        className="signInFieldSelect"
                        style={{ height: 40, width: "100%" }}
                        // disabled={isConfirmed || assignedDeliveryId}
                        placeholder="Select kitchen"
                        // value={deliveryPartner}
                        // onChange={(e) => setDeliveryPartner(e)}
                        options={[...kitchenListOptions]}
                    />
                </Form.Item>
                <Form.Item
                    className='editDishModalFormItem'
                    label="Prep Time"
                    name="prepTime"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your preparation time!',
                        },
                    ]}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <div>
                <Row>
                    <Col span={7}>
                        <label className="form-chef-location-label">Chef location</label>
                    </Col>
                    <Col span={17}>
                        <Form.Item
                        className="editDishModalFormItem"
                        label="lat"
                        name="lat"
                        rules={[
                            {
                            required: true,
                            message: "Please enter Chef location lat!",
                            },
                        ]}
                        >
                        <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                        className="editDishModalFormItem"
                        label="lng"
                        name="lng"
                        rules={[
                            {
                            required: true,
                            message: "Please enter Chef location lng!",
                            },
                        ]}
                        >
                        <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    </Row>
                </div>
                <ChefScheduleTimePicker chefSchedule={chefSchedule} initialChefSchedule={initialChefSchedule} form={form} setChefSchedule={setChefSchedule} />
                {/* <QualificationsFormComponent chefQualification={chefQualification} onNewQualificationClick={onNewQualificationClick} onValueChange={onValueChange} /> */}
                <PastExperiencesComponent chefExperiences={chefExperiences} onNewExperiencesClick={onNewExperiencesClick} onExperienceValueChange={onExperienceValueChange} />
                <Form.Item
                    wrapperCol={{
                        offset: 7,
                        span: 22,
                    }}
                >
                    <Button loading={addChefState?.loading} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateChefModal