import * as React from "react";
const DownArrow = (props) => (
  <svg
    fill="#808080"
    height="12px"
    width="12px"
    id="Layer_111"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 33 33"
    xmlSpace="preserve"
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <path
        id="XMLID_225_"
        d="M32.561 7.939c-0.586 -0.586 -1.536 -0.586 -2.121 0l-13.939 13.939L2.561 7.939c-0.586 -0.586 -1.536 -0.586 -2.121 0 -0.586 0.586 -0.586 1.536 0 2.121l15 15a1.5 1.5 0 0 0 2.121 0l15 -15c0.586 -0.586 0.586 -1.536 0 -2.121z"
      />
    </g>
  </svg>
);
export default DownArrow;
