import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AWS from 'aws-sdk';
import { DISH_IMAGE, DISH_IMAGE_STORY, LOGO_IMAGE } from '../../utils/constant';

const DishImageUpload = (props) => {
    const { isChefLogo = false, isMenuLogo = false, form, setNewDishImg, newDishImg } = props;

    const dishBasePath = isChefLogo ? LOGO_IMAGE : (isMenuLogo ? DISH_IMAGE_STORY : DISH_IMAGE);

    const imgUrl = dishBasePath + props?.img
    const [imageUrl, setImageUrl] = useState(imgUrl);

    useEffect(() =>{
        if(newDishImg){
            setImageUrl(dishBasePath + newDishImg)
        }
    },[newDishImg])

    // Function to handle file upload changes
    const handleChange = info => {
        // if (info.file.status === 'done') {
        //     // When upload is successful, update the image URL
        //     setImageUrl(info.file.response.url);
        //     message.success(`${info.file.name} file uploaded successfully`);
        // } else if (info.file.status === 'error') {
        //     message.error(`${info.file.name} file upload failed.`);
        // }
    };

    const customRequest = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        try {
            const S3_BUCKET = "cuirato-images";
            const REGION = "ap-south-1";

            AWS.config.update({
                accessKeyId: "AKIAZOY6NWS6HO7NR7PG",
                secretAccessKey: "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",
            });
            const s3 = new AWS.S3({
                params: { Bucket: S3_BUCKET },
                region: REGION,
            });
            const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
            const fileName = randomNumber + file.name;
            const filePath = isChefLogo ? `logo/${fileName}` : (isMenuLogo ? `menu/${fileName}` : `menu/thumbnail/${fileName}` )
            const params = {
                Bucket: S3_BUCKET,
                Key: filePath,
                Body: file,
            };

            var upload = s3
                .putObject(params)
                .on("httpUploadProgress", (evt) => {
                    console.log(
                        "response Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                    );
                })
                .promise();

            await upload.then((err, data) => {
                console.log('fileName', fileName)
                setNewDishImg(fileName)
                if(!isChefLogo && !isMenuLogo){
                    form.setFieldValue('profileImg', fileName)
                    form.setFieldValue('chefImgTh', fileName)
                }
                
            });
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    }

    // Customize the upload button
    const uploadButton = (
        <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Upload
            name="image"
            action="/api/upload" // Replace with your backend API endpoint for file upload
            listType="picture-circle"
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleChange}
            customRequest={customRequest}
        >
            {imageUrl ? (
                <span className='dish-img-band'>
                    <img className='dish-img-band-img' src={imageUrl} />
                </span>
            ) : (
                uploadButton
            )}
        </Upload>
    );
};

export default DishImageUpload;
