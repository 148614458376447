import React, { useRef, useEffect } from 'react';
import "./orderMailScreen.css"
import { Button } from 'antd';

const MapComponent = ({ lat, lng }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const mapOptions = {
      center: { lat, lng },
      zoom: 16
    };

    const map = new window.google.maps.Map(mapRef.current, mapOptions);

    const marker = new window.google.maps.Marker({
      position: { lat, lng },
      map: map
    });

    return () => {
      marker.setMap(null); // Clean up marker when component is unmounted
    };
  }, [lat, lng]);

  const openInGoogleMaps = () => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl);
  };

  return (
    <div>
      <div ref={mapRef} style={{ height: '250px', width: '100%' }}></div>
      <div style={{marginTop:10}} className='text-center'>
      <Button style={{ height: '36px' }} className='openMapsButton' onClick={openInGoogleMaps}>Open in Google Maps</Button>
      </div>
    </div>
  );
};

export default MapComponent;