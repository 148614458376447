import * as React from "react";
const LocationIcon = (props) => (
  <svg
    fill="#2B6EEA"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 5.859 5.859"
    xmlSpace="preserve"
    width={15}
    height={15}
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <style type="text/css">{".st0{fill:none;}"}</style>
      <path d="M2.93 0.488a1.469 1.469 0 0 0 -1.465 1.465c0 1.269 1.465 2.71 1.465 2.71s1.464 -1.464 1.464 -2.71A1.469 1.469 0 0 0 2.93 0.488zm0 0.953c0.269 0 0.513 0.244 0.513 0.513 0 0.293 -0.22 0.513 -0.513 0.513s-0.513 -0.245 -0.513 -0.514c0 -0.293 0.244 -0.513 0.513 -0.513zM1.661 3.687c-0.318 0.073 -0.562 0.146 -0.781 0.244 -0.098 0.049 -0.196 0.122 -0.269 0.196s-0.122 0.22 -0.122 0.342c0 0.196 0.122 0.342 0.269 0.439s0.318 0.171 0.537 0.244c0.439 0.146 1.001 0.22 1.636 0.22s1.196 -0.073 1.636 -0.196c0.22 -0.073 0.391 -0.146 0.537 -0.244s0.269 -0.244 0.269 -0.439c0 -0.244 -0.196 -0.415 -0.391 -0.537 -0.196 -0.122 -0.464 -0.196 -0.781 -0.244l-0.073 0.488c0.269 0.049 0.488 0.122 0.635 0.196 0.098 0.049 0.122 0.098 0.146 0.098l-0.049 0.049a1.523 1.523 0 0 1 -0.391 0.171c-0.415 0.098 -0.953 0.171 -1.538 0.171s-1.123 -0.073 -1.489 -0.196a1.511 1.511 0 0 1 -0.391 -0.171l-0.049 -0.049c0.024 -0.024 0.049 -0.049 0.122 -0.098 0.146 -0.073 0.366 -0.146 0.659 -0.196L1.66 3.688z" />
      <path className="st0" width={24} height={24} d="M0 0h5.859v5.859H0V0z" />
    </g>
  </svg>
);
export default LocationIcon;
