import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeUpArrow from "../../assets/ReactIcon/SwipeUpArrow";
import { Drawer } from "antd";
import CloseIcon from "../../assets/ReactIcon/CloseIcon";
import OrderViewCard from "../OrderViewCard/Index";
import { updateOrders } from "../../redux/actions/OrderAction";
import { isDriverUser } from "../../utils/helper";
import "./newOrderBottomBar.css";

const NewOrderBottomBar = ({ isDesktopView }) => {
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const orderReducer = useSelector((state) => state.orders.orders);
  const updateOrderReducer = useSelector((state) => state.orders.updateOrders);
  const orderPendingOrder = orderReducer?.pendingOrders || [];
  const allOrderPendingData = orderReducer?.data || [];

  const driverNewOrders = allOrderPendingData.filter((a) =>
    ["CREATED", "CONFIRMED"].includes(a.status)
  );

  const onClose = () => {
    setOpenDrawer(false);
  };
  const onOpen = () => {
    setOpenDrawer(true);
  };

  const onButtonClick = (id) => {
    dispatch(
      updateOrders({
        id: id,
        status: "CONFIRMED",
      })
    );
  };

  // useEffect(() => {
  //   if (updateOrderReducer?.success && updateOrderReducer.data) {
  //     console.log('getOrdersAction updateOrderReducer?.success && updateOrderReducer.data')
  //     getOrdersAction();
  //   }
  // }, [updateOrderReducer]);

  if (isDriverUser()) {
    if (!driverNewOrders.length) return <></>;
  } else {
    if (!orderPendingOrder.length) return <></>;
  }
  const newOrderBottomBarOrderList = isDriverUser()
    ? driverNewOrders
    : orderPendingOrder;

  return (
    <div className="newOrderPromptWrapper">
      <button onClick={onOpen} className="newOrderPromptButton">
        <SwipeUpArrow />
        <div className="newOrderPromptText">
          You have {newOrderBottomBarOrderList.length} new order
        </div>
      </button>
      <Drawer
        title={`${newOrderBottomBarOrderList.length} new order`}
        placement="bottom"
        height="100%"
        closeIcon={<CloseIcon />}
        onClose={onClose}
        open={openDrawer}
      >
        <div style={{ marginTop: 0 }} className="orderViewCardWrapper">
          {newOrderBottomBarOrderList.map((order, Index) => (
            <OrderViewCard
              key={Index}
              acceptButtonText={!isDriverUser() && "Accept"}
              showOnlyLocationButton
              order={order}
              isDesktopView={isDesktopView}
              onButtonClick={onButtonClick}
            />
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default NewOrderBottomBar;
