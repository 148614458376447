import React, { useState } from "react";
import "./orderScreen.css";
import { Drawer, InputNumber, Switch } from "antd";
import CloseIcon from "../../assets/ReactIcon/CloseIcon";
import { useDispatch, useSelector } from "react-redux";
import { updateChefStatus } from "../../redux/actions/OrderAction";

const KitchenSettingDrawer = ({
    onClose,
    openDrawer
}) => {
    const dispatch = useDispatch();
    const chefList = useSelector(
        (state) => state.orders.chefsList.data
    );

    const onAvailableChange = (chefId, isSwitchOff) => {
        dispatch(updateChefStatus({ chefId, isSwitchOff }))
    }

    const handlePrepTime = (chefId, prepTime) => {
        if (!prepTime) return
        dispatch(updateChefStatus({ chefId, prepTime: +prepTime }))
    }

    return (

        <Drawer
            title={`Kitchen Settings`}
            placement="right"
            closeIcon={<CloseIcon />}
            onClose={onClose}
            open={openDrawer}
        >
            <div style={{marginTop:0}} className="kitchenSettingDrawer">
                <h5 style={{ marginBottom: 12, fontWeight: 600 }}>Chef details</h5>
                <div className="chefDetailsWrapper">
                    {chefList.map((chef) => (
                        <div key={chef._id} className="chefInfoContainer">
                            <div className="chefAvailableLabel">
                                <span style={{ fontSize: 14 }}>Chef's name</span>
                                <span className="chefInfoName">{chef.name}</span>
                            </div>
                            <span className="chefAvailableLabel"><span style={{ fontSize: 14 }}>Available</span> <Switch style={{width:80, marginTop:4}} checkedChildren="Online" onChange={(e) => onAvailableChange(chef._id, !e)} unCheckedChildren="Offline" defaultChecked={!chef?.isSwitchOff} /></span>
                            <div className="chefPrepTime">
                                <span style={{ fontSize: 14 }}>Prep time</span>
                                <InputNumber defaultValue={chef?.prepTime} onBlur={(e) => handlePrepTime(chef._id, e.target.value)} /></div>
                        </div>
                    ))}
                </div>
            </div>
        </Drawer>
    );
};

export default KitchenSettingDrawer;
