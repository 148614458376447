import * as React from "react";
const MenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.000000pt"
    height="12.000000pt"
    viewBox="0 0 10 10"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g transform="matrix(.1 0 0 -.1 0 10)" fill="#000000" stroke="none">
      <path d="M10.176 96.563c-3.301 -0.879 -5.879 -3.516 -6.758 -6.855 -0.449 -1.699 -0.43 -26.406 0.02 -28.379 0.684 -3.047 2.969 -5.527 6.152 -6.68l1.25 -0.449 12.773 -0.059c8.32 -0.039 13.262 0.02 14.16 0.156 4.043 0.605 7.324 3.887 7.93 7.93 0.137 0.898 0.195 5.84 0.156 14.16l-0.059 12.773 -0.449 1.25c-1.152 3.184 -3.633 5.469 -6.68 6.152 -1.934 0.449 -26.875 0.449 -28.496 0zm27.695 -6.328c0.43 -0.215 0.977 -0.703 1.211 -1.113l0.469 -0.742 0.059 -12.207c0.039 -6.719 0 -12.578 -0.059 -13.047 -0.156 -1.094 -1.211 -2.305 -2.266 -2.578 -0.488 -0.137 -5.039 -0.195 -13.203 -0.156l-12.461 0.059 -0.742 0.469c-0.41 0.234 -0.898 0.781 -1.113 1.211 -0.371 0.723 -0.391 1.191 -0.391 13.34 0 8.438 0.078 12.773 0.215 13.145 0.273 0.723 0.918 1.426 1.641 1.738 0.469 0.215 3.125 0.254 13.242 0.273 12.207 0 12.676 -0.02 13.398 -0.391z" />
      <path d="M61.133 96.523c-3.027 -0.801 -5.352 -2.969 -6.484 -6.113l-0.449 -1.25 -0.059 -12.773c-0.039 -8.32 0.02 -13.262 0.156 -14.16 0.605 -4.043 3.887 -7.324 7.93 -7.93 0.898 -0.137 5.84 -0.195 14.16 -0.156l12.773 0.059 1.25 0.449c2.324 0.84 3.945 2.168 5.176 4.199 1.27 2.129 1.289 2.363 1.289 16.602 0 7.871 -0.078 13.184 -0.195 13.828 -0.488 2.559 -2.422 5.195 -4.668 6.406 -2.266 1.211 -2.129 1.191 -16.523 1.172 -11.66 0 -13.223 -0.039 -14.355 -0.332zm27.617 -6.172c0.352 -0.156 0.898 -0.605 1.211 -1.016l0.566 -0.723 0.059 -12.871c0.039 -12.5 0.039 -12.871 -0.332 -13.613 -0.215 -0.41 -0.723 -0.977 -1.133 -1.211l-0.742 -0.469 -12.207 -0.059c-6.719 -0.039 -12.578 0 -13.047 0.059 -1.094 0.156 -2.305 1.211 -2.578 2.266 -0.137 0.488 -0.195 5.039 -0.156 13.203 0.059 12.07 0.078 12.48 0.449 13.105 0.215 0.371 0.742 0.879 1.172 1.133l0.781 0.469h12.656c10.371 0 12.773 -0.059 13.301 -0.273z" />
      <path d="M11.035 45.684c-3.75 -0.625 -6.797 -3.438 -7.598 -7.012 -0.449 -1.992 -0.469 -26.68 -0.02 -28.418 0.918 -3.477 3.848 -6.27 7.305 -6.934 0.645 -0.117 5.938 -0.195 13.965 -0.176 14.395 0 14.355 0 16.602 1.348s3.984 3.984 4.414 6.641c0.254 1.68 0.254 25.039 0 26.758 -0.605 3.887 -3.926 7.207 -7.813 7.813 -1.523 0.234 -25.41 0.234 -26.855 -0.02zm26.309 -6.23c0.996 -0.293 2.051 -1.504 2.207 -2.578 0.059 -0.469 0.098 -6.328 0.059 -13.047l-0.059 -12.207 -0.469 -0.742c-0.234 -0.41 -0.801 -0.918 -1.211 -1.133 -0.742 -0.371 -1.113 -0.371 -13.613 -0.332l-12.871 0.059 -0.723 0.566c-1.328 1.016 -1.289 0.508 -1.289 14.473 0 12.109 0.02 12.637 0.371 13.359 0.41 0.762 1.094 1.328 1.953 1.582 0.781 0.234 24.785 0.234 25.645 0z" />
      <path d="M73.047 45.684c-5.469 -0.664 -10.723 -3.574 -14.16 -7.852 -1.543 -1.895 -3.418 -5.566 -3.984 -7.793 -1.699 -6.641 -0.273 -13.574 3.887 -18.77 2.598 -3.262 5.84 -5.566 9.766 -6.934 2.598 -0.898 4.082 -1.113 7.324 -1.094 2.48 0.02 3.223 0.098 4.844 0.527 6.035 1.602 11.094 5.684 13.887 11.211 4.492 8.945 2.031 20 -5.84 26.23 -1.523 1.211 -3.965 2.598 -5.664 3.242 -2.988 1.113 -7.031 1.621 -10.059 1.23zm6.055 -6.563c2.852 -0.684 6.016 -2.598 7.813 -4.766 5.215 -6.27 4.883 -14.844 -0.762 -20.508 -8.008 -8.008 -21.445 -4.746 -25 6.055 -2.539 7.734 1.914 16.426 9.648 18.887 2.813 0.898 5.508 1.016 8.301 0.332z" />
    </g>
  </svg>
);
export default MenuIcon;
