import React, { useState } from "react";
import "./orderScreen.css";
import { isDriverUser } from "../../utils/helper";
import MenuIcon from "../../assets/ReactIcon/MenuIcon";
import KitchenSettingDrawer from "./KitchenSettingDrawer";
import DishesIcon from "../../assets/ReactIcon/DishesIcon";
import { useNavigate } from "react-router-dom";
import BackBoldIcon from "../../assets/ReactIcon/BackBoldIcon";
import CreateKitchenModal from "../CreateKitchenModal";
import CreateChefModal from "../CreateChefModal/Index";

const KitchenTopBar = ({
  status,
  setStatus,
  confirmedOrdersCount,
  readyOrdersCount,
  pickedUpOrdersCount,
  pickedUpOrderDataListCount,
  deliveredOrderDataListCount,
  showStatusBar = false,
  isHomePage
}) => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createKitchenModal, setCreateKitchenModal] = useState(false);
  const [createChefModal, setCreateChefModal] = useState(false);

  const onClose = () => {
    setOpenDrawer(false);
  };
  const onOpen = () => {
    setOpenDrawer(true);
  };


  const onDishesDrawerOpen = () => {
    navigate('/chefdishes');
  };

  const handleCloseCreateModal = () => {
    setCreateKitchenModal(false)
  }
  const handleOpenCreateModal = () => {
    setCreateKitchenModal(true)
  }

  const handleCloseChefModal = () => {
    setCreateChefModal(false)
  }
  const handleOpenChefModal = () => {
    setCreateChefModal(true)
  }

  const onHomeClick = () => {
    navigate('/');
  };

  return (
    <div className="kitchenTopBar">
      {!isDriverUser() && <div className="kitchenMenuTopBar">
        <div>
          {!isHomePage && <button onClick={onHomeClick} className="kitchenCloseIcon">
            <BackBoldIcon />
          </button>}
        </div>
        <div style={{ display: "flex" }}>
          <button style={{ marginRight: 10 }} className='createKitchen' onClick={handleOpenChefModal}>
            Create Chef
          </button>
          <button style={{ marginRight: 10 }} className='createKitchen' onClick={handleOpenCreateModal}>
            Create kitchen
          </button>
          {isHomePage && <button onClick={onDishesDrawerOpen} className="kitchenCloseIcon">
            <DishesIcon />
          </button>}
          <button style={{ marginLeft: 6 }} onClick={onOpen} className="kitchenCloseIcon">
            <MenuIcon />
          </button>
        </div>
      </div>}
      {showStatusBar && <div className="kitchenButtonTopBar">
        {isDriverUser() ? (
          <>
            <button
              className={`kitchenTopBarBtn ${status === "READY" ? "kitchenTopBarBtnActive" : ""
                }`}
              onClick={() => setStatus("READY")}
            >
              Ready(Pickup) ({readyOrdersCount})
            </button>
            <button
              className={`kitchenTopBarBtn ${status === "PICKED_UP" ? "kitchenTopBarBtnActive" : ""
                }`}
              onClick={() => setStatus("PICKED_UP")}
            >
              Out For Delivery({pickedUpOrderDataListCount})
            </button>
            <button
              className={`kitchenTopBarBtn ${status === "DELIVERED" ? "kitchenTopBarBtnActive" : ""
                }`}
              onClick={() => setStatus("DELIVERED")}
            >
              Delivered({deliveredOrderDataListCount})
            </button>
          </>
        ) : (
          <>
            <button
              className={`kitchenTopBarBtn ${status === "PREPARING" ? "kitchenTopBarBtnActive" : ""
                }`}
              onClick={() => setStatus("PREPARING")}
            >
              Preparing ({confirmedOrdersCount})
            </button>
            <button
              className={`kitchenTopBarBtn ${status === "READY" ? "kitchenTopBarBtnActive" : ""
                }`}
              onClick={() => setStatus("READY")}
            >
              Ready ({readyOrdersCount})
            </button>
            <button
              className={`kitchenTopBarBtn ${status === "PICKED_UP" ? "kitchenTopBarBtnActive" : ""
                }`}
              onClick={() => setStatus("PICKED_UP")}
            >
              Picked up ({pickedUpOrdersCount})
            </button>
          </>
        )}
      </div>}
      <KitchenSettingDrawer onClose={onClose} openDrawer={openDrawer} />
      <CreateKitchenModal isModalOpen={createKitchenModal} handleCancel={handleCloseCreateModal} />
      <CreateChefModal isModalOpen={createChefModal} handleCancel={handleCloseChefModal} />
      {/* <ChefDishesDrawer onClose={onDishesDrawerClose} openDrawer={openDishesDrawer} /> */}
    </div>
  );
};

export default KitchenTopBar;
