import React from "react";
import "./orderViewCard.css";

const TotalBillSummaryBox = ({
  deliveryFee,
  packagingFee,
  gstFee,
  billingAmount,
}) => {
  return (
    <div className="totalBillSummaryBox">
      <div className="billInfoWrapper">
        <span className="totalBillLabel">Delivery Fees</span>
        <div className="totalBillAmount">₹{deliveryFee?.toFixed(2)}</div>
      </div>
      <div className="billInfoWrapper">
        <span className="totalBillLabel">Packaging Fees</span>
        <div className="totalBillAmount">₹{packagingFee?.toFixed(2)}</div>
      </div>
      <div className="billInfoWrapper">
        <span className="totalBillLabel">GST</span>
        <div className="totalBillAmount">₹{gstFee?.toFixed(2)}</div>
      </div>
      <div className="billInfoWrapper">
        <span className="totalBillLabel">Total Bill Amount</span>
        <div className="totalBillAmount">₹{billingAmount?.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default TotalBillSummaryBox;
